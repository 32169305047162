import {
  GET_MIDDLEBANNERS_STATED,
  GET_MIDDLEBANNERS,
  GET_MIDDLEBANNERS_ENDED,
  ADD_MIDDLEBANNER_STATED,
  ADD_MIDDLEBANNER,
  ADD_MIDDLEBANNER_ENDED,
  EDIT_MIDDLEBANNER_STATED,
  EDIT_MIDDLEBANNER,
  EDIT_MIDDLEBANNER_ENDED,
  GET_MIDDLEBANNER_STATED,
  GET_MIDDLEBANNER,
  GET_MIDDLEBANNER_ENDED,
  GET_ALL_MIDDLEBANNERS_STATED,
  GET_ALL_MIDDLEBANNERS,
  GET_ALL_MIDDLEBANNERS_ENDED
} from "../types/middlebanner_type";

const initialState = {
  middlebanners_loading: true,
  middlebanners: null,
  page: null,
  pages: null,
  total_middlebanners: 0,

  middlebanner: null,
  middlebanner_loading: null,

  loading: true,

  middlebanner_message: null,
  all_middlebanners: null,
  all_middlebanners_loading: null,
  add_middlebanner_loading: true,
  edit_middlebanner_loading: true
};

export const middlebanner_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_MIDDLEBANNERS_STATED:
      return {
        ...state,
        middlebanners: null,
        pages: null,
        page: null,
        total_middlebanners: 0,
        middlebanners_loading: true
      };
    case GET_MIDDLEBANNERS:
      return {
        ...state,
        middlebanners: payload.middlebanners,
        pages: payload.pages,
        page: payload.page,
        total_middlebanners: payload.count
      };
    case GET_MIDDLEBANNERS_ENDED:
      return {
        ...state,
        middlebanners_loading: false
      };
    case GET_ALL_MIDDLEBANNERS_STATED:
      return {
        ...state,
        all_middlebanners_loading: true,
        all_middlebanners: null
      };
    case GET_ALL_MIDDLEBANNERS:
      return {
        ...state,
        all_middlebanners: payload
      };
    case GET_ALL_MIDDLEBANNERS_ENDED:
      return {
        ...state,
        all_middlebanners_loading: false
      };

    case ADD_MIDDLEBANNER_STATED:
      return {
        ...state,
        middlebanner_message: null,
        add_middlebanner_loading: true
      };
    case ADD_MIDDLEBANNER:
      return {
        ...state,
        middlebanner_message: payload
      };
    case ADD_MIDDLEBANNER_ENDED:
      return {
        ...state,
        add_middlebanner_loading: false
      };
    case GET_MIDDLEBANNER_STATED:
      return {
        ...state,
        middlebanner: null,
        middlebanner_loading: true
      };
    case GET_MIDDLEBANNER:
      return {
        ...state,
        middlebanner: payload
      };
    case GET_MIDDLEBANNER_ENDED:
      return {
        ...state,
        middlebanner_loading: false
      };
    case EDIT_MIDDLEBANNER_STATED:
      return {
        ...state,
        middlebanner_message: null,
        edit_middlebanner_loading: true
      };
    case EDIT_MIDDLEBANNER:
      return {
        ...state,
        middlebanner_message: payload
      };
    case EDIT_MIDDLEBANNER_ENDED:
      return {
        ...state,
        edit_middlebanner_loading: false
      };

    default:
      return state;
  }
};
