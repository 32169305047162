export const PAGE_TITLE = 'Video Courses'
export const PAGE_SINGLE_TITLE = 'Video Course'
export const LINK_URL = 'video-courses'
export const inputFields = {
  title: {
    type: 'string',
    required: true,
    title: 'Title',
    inputType: '',
    options: '',
    field: '',
  },
  image: {
    type: 'file',
    required: false,
    title: 'Featured Image (360*180)',
    inputType: '',
    options: '',
    field: '',
  },
  gallery: {
    type: 'gallery',
    required: false,
    title: 'Gallery (360*180) ',
    inputType: '',
    options: '',
    field: '',
  },
  about_course: {
    type: 'html',
    required: false,
    title: 'About Course',
    inputType: '',
    options: '',
    field: '',
  },
  regular_price: {
    type: 'string',
    required: true,
    title: 'Regular Price',
    inputType: '',
    options: '',
    field: '',
  },
  sale_price: {
    type: 'string',
    required: true,
    title: 'Sale Price',
    inputType: '',
    options: '',
    field: '',
  },
  // validity: {
  //   type: "string",
  //   required: false,
  //   title: "Validity",
  //   inputType: "",
  //   options: "",
  //   field: "",
  // },
  category: {
    type: 'related',
    required: false,
    title: 'Category',
    inputType: '',
    options: '',
    field: 'name',
  },
  trailer_url: {
    type: 'string',
    required: false,
    title: 'Trailer URL',
    inputType: '',
    options: '',
    field: '',
  },
}
export const initialValues = {
  name: '',
}

export const view_all_table = [
  { name: 'Title', value: 'title' },
  { name: 'Regular Price', value: 'regular_price' },
  { name: 'Sale Price', value: 'sale_price' },
  { name: 'Category', value: 'category', related: true, field: 'name' },
]

export const SIDEBAR_OPTIONS = [
  {
    id: 'name',
    field: 'name',
    label: 'Name',
    type: 'string',
    search_type: 'search',
    inputType: 'text',
    condition: '',
  },
  {
    id: 'category',
    field: 'category',
    label: 'Category',
    type: 'related',
    search_type: 'exact',
    inputType: 'text',
    condition: '',
  },
]
