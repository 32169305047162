export const PAGE_TITLE = 'Middle Banners'
export const PAGE_SINGLE_TITLE = 'Middle Banner'
export const LINK_URL = 'middle-banners'
export const inputFields = {
  image: {
    type: 'file',
    required: false,
    title: 'Image(1200*800)',
    inputType: '',
    options: '',
    field: '',
  },
  screen_name: {
    type: 'select',
    required: true,
    title: 'Screen Name',
    inputType: '',
    options: ['Home', 'Jobs'],
    field: '',
  },
}
export const initialValues = {
  screen_name: '',
}

export const view_all_table = [
  { name: 'Screen Name', value: 'screen_name' },
  { name: 'Image', value: 'image', image: true },
]

export const SIDEBAR_OPTIONS = [
  {
    id: 'screen_name',
    field: 'screen_name',
    label: 'Screen Name',
    type: 'string',
    search_type: 'search',
    inputType: 'text',
    condition: '',
  },
]
