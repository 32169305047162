import api from "../../domain/api";
import {
  GET_CELEBRITYS_STATED,
  GET_CELEBRITYS,
  GET_CELEBRITYS_ENDED,
  ADD_CELEBRITY_STATED,
  ADD_CELEBRITY,
  ADD_CELEBRITY_ENDED,
  EDIT_CELEBRITY_STATED,
  EDIT_CELEBRITY,
  EDIT_CELEBRITY_ENDED,
  GET_CELEBRITY_STATED,
  GET_CELEBRITY,
  GET_CELEBRITY_ENDED,
  GET_ALL_CELEBRITYS_STATED,
  GET_ALL_CELEBRITYS,
  GET_ALL_CELEBRITYS_ENDED,
} from "../types/celebrity_type";
import * as qs from "qs";
import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";

export const addCelebrity = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_CELEBRITY_STATED,
    });
    const { data } = await api.post(`/celebritys`, formData);
    dispatch({
      type: ADD_CELEBRITY,
      payload: data,
    });
    dispatch({
      type: ADD_CELEBRITY_ENDED,
    });
  } catch (error) {
    dispatch({
      type: ADD_CELEBRITY_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getCelebritys =
  ({ pageNumber = ""}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_CELEBRITYS_STATED,
      });
      const queryParams = qs.parse(window.location.search.replace("?", ""));
      const query = qs.stringify(queryParams, {
        encodeValuesOnly: true, // prettify url
      });

      const { data } = await api.get(
        `/celebritys?&pageNumber=${pageNumber}&${query}`
      );

      dispatch({
        type: GET_CELEBRITYS,
        payload: data,
      });
      dispatch({
        type: GET_CELEBRITYS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_CELEBRITYS_ENDED,
      });
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const getCelebrity = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_CELEBRITY_STATED,
    });
    const { data } = await api.get(`/celebritys/${id}`);

    dispatch({
      type: GET_CELEBRITY,
      payload: data,
    });
    dispatch({
      type: GET_CELEBRITY_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_CELEBRITY_STATED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editCelebrity = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_CELEBRITY_STATED,
    });
    const { data } = await api.put(`/celebritys/${id}`, formData);
    dispatch({
      type: EDIT_CELEBRITY,
      payload: data,
    });
    dispatch({
      type: EDIT_CELEBRITY_ENDED,
    });
  } catch (error) {
    dispatch({
      type: EDIT_CELEBRITY_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteCelebrity = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/celebritys/${id}`);
    dispatch(setAlert("Celebrity Deleted Successfully", "success"));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllCelebritys = ({ term, value }) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_CELEBRITYS_STATED,
    });
    const { data } = await api.get(`/celebritys/all?term=${term}&value=${value}`);

    dispatch({
      type: GET_ALL_CELEBRITYS,
      payload: data,
    });
    dispatch({
      type: GET_ALL_CELEBRITYS_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_CELEBRITYS_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};

export const handleErrorLocal = () => async (dispatch) => {};
