export const PAGE_TITLE = "Applicants";
export const PAGE_SINGLE_TITLE = "Applicant";
export const LINK_URL = "applicants";
export const inputFields = {
  name: {
    type: "string",
    required: true,
    title: "Name",
    inputType: "",
    options: "",
    field: "",
  },
  email: {
    type: "string",
    required: true,
    title: "Email",
    inputType: "",
    options: "",
    field: "",
  },
  phone: {
    type: "string",
    required: true,
    title: "Phone",
    inputType: "",
    options: "",
    field: "",
  },
  state: {
    type: "string",
    required: true,
    title: "State",
    inputType: "",
    options: "",
    field: "",
  },
  city: {
    type: "string",
    required: true,
    title: "City",
    inputType: "",
    options: "",
    field: "",
  },
  address: {
    type: "string",
    required: false,
    title: "Address",
    inputType: "",
    options: "",
    field: "",
  },
  pincode: {
    type: "string",
    required: false,
    title: "Pincode",
    inputType: "",
    options: "",
    field: "",
  },
  job: {
    type: "related",
    required: true,
    title: "Job",
    inputType: "",
    options: "",
    field: "title",
  },
};
export const initialValues = {
  name: "",
  email: "",
  phone: "",
  state: "",
  city: "",
  address: "",
  pincode: "",
  job: "",
};

export const view_all_table = [
  { name: "name", value: "name" },
  { name: "email", value: "email" },
  { name: "phone", value: "phone" },
  { name: "state", value: "state" },
  { name: "city", value: "city" },
  { name: "address", value: "address" },
  { name: "pincode", value: "pincode" },
  { name: "job", value: "job", related: true, field: "title" },
];

export const SIDEBAR_OPTIONS = [
  {
    id: "name",
    field: "name",
    label: "Name",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "job",
    field: "job",
    label: "Job",
    type: "related",
    search_type: "exact",
    inputType: "text",
    condition: "",
  },
  {
    id: "email",
    field: "email",
    label: "Email",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "phone",
    field: "phone",
    label: "Phone",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
];
