import {
  GET_AGENCYBANNERS_STATED,
  GET_AGENCYBANNERS,
  GET_AGENCYBANNERS_ENDED,
  ADD_AGENCYBANNER_STATED,
  ADD_AGENCYBANNER,
  ADD_AGENCYBANNER_ENDED,
  EDIT_AGENCYBANNER_STATED,
  EDIT_AGENCYBANNER,
  EDIT_AGENCYBANNER_ENDED,
  GET_AGENCYBANNER_STATED,
  GET_AGENCYBANNER,
  GET_AGENCYBANNER_ENDED,
  GET_ALL_AGENCYBANNERS_STATED,
  GET_ALL_AGENCYBANNERS,
  GET_ALL_AGENCYBANNERS_ENDED
} from "../types/agencybanner_type";

const initialState = {
  agencybanners_loading: true,
  agencybanners: null,
  page: null,
  pages: null,
  total_agencybanners: 0,

  agencybanner: null,
  agencybanner_loading: null,

  loading: true,

  agencybanner_message: null,
  all_agencybanners: null,
  all_agencybanners_loading: null,
  add_agencybanner_loading: true,
  edit_agencybanner_loading: true
};

export const agencybanner_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_AGENCYBANNERS_STATED:
      return {
        ...state,
        agencybanners: null,
        pages: null,
        page: null,
        total_agencybanners: 0,
        agencybanners_loading: true
      };
    case GET_AGENCYBANNERS:
      return {
        ...state,
        agencybanners: payload.agencybanners,
        pages: payload.pages,
        page: payload.page,
        total_agencybanners: payload.count
      };
    case GET_AGENCYBANNERS_ENDED:
      return {
        ...state,
        agencybanners_loading: false
      };
    case GET_ALL_AGENCYBANNERS_STATED:
      return {
        ...state,
        all_agencybanners_loading: true,
        all_agencybanners: null
      };
    case GET_ALL_AGENCYBANNERS:
      return {
        ...state,
        all_agencybanners: payload
      };
    case GET_ALL_AGENCYBANNERS_ENDED:
      return {
        ...state,
        all_agencybanners_loading: false
      };

    case ADD_AGENCYBANNER_STATED:
      return {
        ...state,
        agencybanner_message: null,
        add_agencybanner_loading: true
      };
    case ADD_AGENCYBANNER:
      return {
        ...state,
        agencybanner_message: payload
      };
    case ADD_AGENCYBANNER_ENDED:
      return {
        ...state,
        add_agencybanner_loading: false
      };
    case GET_AGENCYBANNER_STATED:
      return {
        ...state,
        agencybanner: null,
        agencybanner_loading: true
      };
    case GET_AGENCYBANNER:
      return {
        ...state,
        agencybanner: payload
      };
    case GET_AGENCYBANNER_ENDED:
      return {
        ...state,
        agencybanner_loading: false
      };
    case EDIT_AGENCYBANNER_STATED:
      return {
        ...state,
        agencybanner_message: null,
        edit_agencybanner_loading: true
      };
    case EDIT_AGENCYBANNER:
      return {
        ...state,
        agencybanner_message: payload
      };
    case EDIT_AGENCYBANNER_ENDED:
      return {
        ...state,
        edit_agencybanner_loading: false
      };

    default:
      return state;
  }
};
