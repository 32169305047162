export const PAGE_TITLE = "Modals";
export const PAGE_SINGLE_TITLE = "Modal";
export const LINK_URL = "modals";
export const inputFields = {
  name: {
    type: "string",
    required: true,
    title: "Name",
    inputType: "",
    options: "",
    field: "",
  },
  phone: {
    type: "string",
    required: true,
    title: "Phone",
    inputType: "",
    options: "",
    field: "",
  },
  image: {
    type: "file",
    required: false,
    title: "Image",
    inputType: "",
    options: "",
    field: "",
  },
  height: {
    type: "string",
    required: true,
    title: "Height",
    inputType: "",
    options: "",
    field: "",
  },
  dob: {
    type: "string",
    required: true,
    title: "DOB",
    inputType: "date",
    options: "",
    field: "",
  },
  category: {
    type: "select",
    required: true,
    title: "Category",
    inputType: "",
    options: ["MALE", "FEMALE", "OTHERS"],
    field: "",
  },
  weight: {
    type: "string",
    required: true,
    title: "Weight",
    inputType: "",
    options: "",
    field: "",
  },
  education: {
    type: "string",
    required: true,
    title: "Education",
    inputType: "",
    options: "",
    field: "",
  },
  industry: {
    type: "string",
    required: true,
    title: "Industry",
    inputType: "",
    options: "",
    field: "",
  },
  experience: {
    type: "string",
    required: false,
    title: "Experience",
    inputType: "",
    options: "",
    field: "",
  },
  description: {
    type: "text",
    required: false,
    title: "Description",
    inputType: "",
    options: "",
    field: "",
  },
  is_featured: {
    type: "checkbox",
    required: false,
    title: "Is Featured?",
    inputType: "",
    options: "",
    field: "",
  },
  password: {
    type: "string",
    required: true,
    title: "Password",
    inputType: "password",
    options: "",
    field: "",
  },
};
export const initialValues = {
  name: "",
};

export const view_all_table = [
  { name: "Name", value: "name" },
  { name: "Phone", value: "phone" },
  { name: "Category", value: "category" },
  { name: "Height", value: "height" },
  { name: "Industry", value: "industry" },
  { name: "Is Featured?", value: "is_featured", boolean: true },
];

export const SIDEBAR_OPTIONS = [
  {
    id: "name",
    field: "name",
    label: "Name",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "category",
    field: "category",
    label: "Category",
    type: "select",
    search_type: "exact",
    inputType: "text",
    options: ["MALE", "FEMALE", "OTHERS"],
    condition: "",
  },
];
