import React, { useEffect } from "react";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import Spinner from "../../components/layout/Spinner";
import {
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
  SIDEBAR_OPTIONS,
} from "../../shared/enums/middlebanners_enum";
import SingleView from "../../components/common/SingleView";
import { useSingleMiddlebanner } from "../../shared/hooks/UseMiddlebanner";
const ViewMiddlebanner = ({ match }) => {
  const [data] = useSingleMiddlebanner(match.params.id);
  const { middlebanner_loading, middlebanner } = data;
  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title={PAGE_SINGLE_TITLE}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink="View"
        />
        {!middlebanner_loading ? (
          middlebanner && (
            <SingleView
              data={middlebanner}
              inputFields={inputFields}
              label={PAGE_SINGLE_TITLE}
              link={LINK_URL}
              id={middlebanner._id}
              SIDEBAR_OPTIONS={SIDEBAR_OPTIONS}
            />
          )
        ) : (
          <div>
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewMiddlebanner;
