import {
  GET_CASTINGBANNERS_STATED,
  GET_CASTINGBANNERS,
  GET_CASTINGBANNERS_ENDED,
  ADD_CASTINGBANNER_STATED,
  ADD_CASTINGBANNER,
  ADD_CASTINGBANNER_ENDED,
  EDIT_CASTINGBANNER_STATED,
  EDIT_CASTINGBANNER,
  EDIT_CASTINGBANNER_ENDED,
  GET_CASTINGBANNER_STATED,
  GET_CASTINGBANNER,
  GET_CASTINGBANNER_ENDED,
  GET_ALL_CASTINGBANNERS_STATED,
  GET_ALL_CASTINGBANNERS,
  GET_ALL_CASTINGBANNERS_ENDED
} from "../types/castingbanner_type";

const initialState = {
  castingbanners_loading: true,
  castingbanners: null,
  page: null,
  pages: null,
  total_castingbanners: 0,

  castingbanner: null,
  castingbanner_loading: null,

  loading: true,

  castingbanner_message: null,
  all_castingbanners: null,
  all_castingbanners_loading: null,
  add_castingbanner_loading: true,
  edit_castingbanner_loading: true
};

export const castingbanner_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_CASTINGBANNERS_STATED:
      return {
        ...state,
        castingbanners: null,
        pages: null,
        page: null,
        total_castingbanners: 0,
        castingbanners_loading: true
      };
    case GET_CASTINGBANNERS:
      return {
        ...state,
        castingbanners: payload.castingbanners,
        pages: payload.pages,
        page: payload.page,
        total_castingbanners: payload.count
      };
    case GET_CASTINGBANNERS_ENDED:
      return {
        ...state,
        castingbanners_loading: false
      };
    case GET_ALL_CASTINGBANNERS_STATED:
      return {
        ...state,
        all_castingbanners_loading: true,
        all_castingbanners: null
      };
    case GET_ALL_CASTINGBANNERS:
      return {
        ...state,
        all_castingbanners: payload
      };
    case GET_ALL_CASTINGBANNERS_ENDED:
      return {
        ...state,
        all_castingbanners_loading: false
      };

    case ADD_CASTINGBANNER_STATED:
      return {
        ...state,
        castingbanner_message: null,
        add_castingbanner_loading: true
      };
    case ADD_CASTINGBANNER:
      return {
        ...state,
        castingbanner_message: payload
      };
    case ADD_CASTINGBANNER_ENDED:
      return {
        ...state,
        add_castingbanner_loading: false
      };
    case GET_CASTINGBANNER_STATED:
      return {
        ...state,
        castingbanner: null,
        castingbanner_loading: true
      };
    case GET_CASTINGBANNER:
      return {
        ...state,
        castingbanner: payload
      };
    case GET_CASTINGBANNER_ENDED:
      return {
        ...state,
        castingbanner_loading: false
      };
    case EDIT_CASTINGBANNER_STATED:
      return {
        ...state,
        castingbanner_message: null,
        edit_castingbanner_loading: true
      };
    case EDIT_CASTINGBANNER:
      return {
        ...state,
        castingbanner_message: payload
      };
    case EDIT_CASTINGBANNER_ENDED:
      return {
        ...state,
        edit_castingbanner_loading: false
      };

    default:
      return state;
  }
};
