import {
  GET_VIDEOCATEGORYS_STATED,
  GET_VIDEOCATEGORYS,
  GET_VIDEOCATEGORYS_ENDED,
  ADD_VIDEOCATEGORY_STATED,
  ADD_VIDEOCATEGORY,
  ADD_VIDEOCATEGORY_ENDED,
  EDIT_VIDEOCATEGORY_STATED,
  EDIT_VIDEOCATEGORY,
  EDIT_VIDEOCATEGORY_ENDED,
  GET_VIDEOCATEGORY_STATED,
  GET_VIDEOCATEGORY,
  GET_VIDEOCATEGORY_ENDED,
  GET_ALL_VIDEOCATEGORYS_STATED,
  GET_ALL_VIDEOCATEGORYS,
  GET_ALL_VIDEOCATEGORYS_ENDED
} from "../types/videocategory_type";

const initialState = {
  videocategorys_loading: true,
  videocategorys: null,
  page: null,
  pages: null,
  total_videocategorys: 0,

  videocategory: null,
  videocategory_loading: null,

  loading: true,

  videocategory_message: null,
  all_videocategorys: null,
  all_videocategorys_loading: null,
  add_videocategory_loading: true,
  edit_videocategory_loading: true
};

export const videocategory_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_VIDEOCATEGORYS_STATED:
      return {
        ...state,
        videocategorys: null,
        pages: null,
        page: null,
        total_videocategorys: 0,
        videocategorys_loading: true
      };
    case GET_VIDEOCATEGORYS:
      return {
        ...state,
        videocategorys: payload.videocategorys,
        pages: payload.pages,
        page: payload.page,
        total_videocategorys: payload.count
      };
    case GET_VIDEOCATEGORYS_ENDED:
      return {
        ...state,
        videocategorys_loading: false
      };
    case GET_ALL_VIDEOCATEGORYS_STATED:
      return {
        ...state,
        all_videocategorys_loading: true,
        all_videocategorys: null
      };
    case GET_ALL_VIDEOCATEGORYS:
      return {
        ...state,
        all_videocategorys: payload
      };
    case GET_ALL_VIDEOCATEGORYS_ENDED:
      return {
        ...state,
        all_videocategorys_loading: false
      };

    case ADD_VIDEOCATEGORY_STATED:
      return {
        ...state,
        videocategory_message: null,
        add_videocategory_loading: true
      };
    case ADD_VIDEOCATEGORY:
      return {
        ...state,
        videocategory_message: payload
      };
    case ADD_VIDEOCATEGORY_ENDED:
      return {
        ...state,
        add_videocategory_loading: false
      };
    case GET_VIDEOCATEGORY_STATED:
      return {
        ...state,
        videocategory: null,
        videocategory_loading: true
      };
    case GET_VIDEOCATEGORY:
      return {
        ...state,
        videocategory: payload
      };
    case GET_VIDEOCATEGORY_ENDED:
      return {
        ...state,
        videocategory_loading: false
      };
    case EDIT_VIDEOCATEGORY_STATED:
      return {
        ...state,
        videocategory_message: null,
        edit_videocategory_loading: true
      };
    case EDIT_VIDEOCATEGORY:
      return {
        ...state,
        videocategory_message: payload
      };
    case EDIT_VIDEOCATEGORY_ENDED:
      return {
        ...state,
        edit_videocategory_loading: false
      };

    default:
      return state;
  }
};
