export const PAGE_TITLE = 'Celebritys'
export const PAGE_SINGLE_TITLE = 'Celebrity'
export const LINK_URL = 'celebritys'
export const inputFields = {
  name: {
    type: 'string',
    required: true,
    title: 'Name',
    inputType: 'text',
  },
  image: {
    type: 'file',
    required: false,
    title: 'Image(640*960)',
    inputType: 'text',
  },
  gallery: {
    type: 'gallery (640*960)',
    required: false,
    title: 'Gallery',
    inputType: 'text',
  },
}
export const initialValues = {
  name: '',
}

export const view_all_table = [{ name: 'Name', value: 'name' }]

export const SIDEBAR_OPTIONS = [
  {
    id: 'name',
    field: 'name',
    label: 'Name',
    type: 'string',
    search_type: 'search',
    inputType: 'text',
    condition: '',
  },
]
