import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addStudymaterial,
  getStudymaterials,
  getStudymaterial,
  editStudymaterial,
  deleteStudymaterial,
  getAllStudymaterials,
} from "../../store/actions/studymaterial_action";
import _debounce from "lodash/debounce";
import { useSelectAllVideocourse } from "./UseVideocourse";

// Get All Data
export const useAllStudymaterials = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.studymaterial);
  const [pageNumber, setPageNumber] = useState(1);
  const [deleteEntry, setDeleteEntry] = useState(null);

  useEffect(() => {
    if (deleteEntry) {
      dispatch(deleteStudymaterial(deleteEntry));
    }
    allQuery();
  }, [deleteEntry, pageNumber, window.location.search]);
  const allQuery = useCallback(
    _debounce(() => {
      dispatch(
        getStudymaterials({
          pageNumber,
        })
      );
    }, 1000),
    []
  );

  useEffect(() => {
    setPageNumber(1);
  }, [window.location.search]);

  const deleteBtnClicked = async (id) => {
    setDeleteEntry(id);
  };

  return [data, setPageNumber, deleteBtnClicked];
};

// Get Single Data
export const useSingleStudymaterial = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.studymaterial);
  useEffect(() => {
    dispatch(getStudymaterial(id));
  }, [id]);
  return [data];
};
// Add Data
export const useCreateStudymaterial = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.studymaterial);
  const addData = async (data) => {
    await dispatch(addStudymaterial(data));
  };
  return [data, addData];
};
export const useUpdateStudymaterial = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.studymaterial);
  const updateData = async (id, data) => {
    await dispatch(editStudymaterial(id, data));
  };
  return [updateData];
};

export const useSelectAllStudymaterial = () => {
  const dispatch = useDispatch();
  const [term, setTerm] = useState("");
  const [value, setValue] = useState("");
  const data = useSelector((state) => state.studymaterial);
  useEffect(() => {
    dispatch(getAllStudymaterials({ term, value }));
  }, [term, value]);
  return [data, setTerm, setValue];
};

export const useGetDropdownOptions = () => {
  const [videocourse, setVideocourseSearchField, setVideocourseSearchValue] =
    useSelectAllVideocourse();

  const [dropdownOptions, setDropdownOptions] = useState({});
  useEffect(() => {
    if (videocourse && videocourse.all_videocourses) {
      const newData = videocourse.all_videocourses.map((item) => {
        return { label: item.title, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, course: newData });
    }
  }, [videocourse]);
  const loadOptions = async (inputValue, callback, field) => {
    if (field == "course") {
      await setVideocourseSearchField("name");
      await setVideocourseSearchValue(inputValue);
      callback(dropdownOptions.course);
    }
  };

  return [dropdownOptions, loadOptions];
};
