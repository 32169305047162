import {
  GET_HEADERBANNERS_STATED,
  GET_HEADERBANNERS,
  GET_HEADERBANNERS_ENDED,
  ADD_HEADERBANNER_STATED,
  ADD_HEADERBANNER,
  ADD_HEADERBANNER_ENDED,
  EDIT_HEADERBANNER_STATED,
  EDIT_HEADERBANNER,
  EDIT_HEADERBANNER_ENDED,
  GET_HEADERBANNER_STATED,
  GET_HEADERBANNER,
  GET_HEADERBANNER_ENDED,
  GET_ALL_HEADERBANNERS_STATED,
  GET_ALL_HEADERBANNERS,
  GET_ALL_HEADERBANNERS_ENDED
} from "../types/headerbanner_type";

const initialState = {
  headerbanners_loading: true,
  headerbanners: null,
  page: null,
  pages: null,
  total_headerbanners: 0,

  headerbanner: null,
  headerbanner_loading: null,

  loading: true,

  headerbanner_message: null,
  all_headerbanners: null,
  all_headerbanners_loading: null,
  add_headerbanner_loading: true,
  edit_headerbanner_loading: true
};

export const headerbanner_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_HEADERBANNERS_STATED:
      return {
        ...state,
        headerbanners: null,
        pages: null,
        page: null,
        total_headerbanners: 0,
        headerbanners_loading: true
      };
    case GET_HEADERBANNERS:
      return {
        ...state,
        headerbanners: payload.headerbanners,
        pages: payload.pages,
        page: payload.page,
        total_headerbanners: payload.count
      };
    case GET_HEADERBANNERS_ENDED:
      return {
        ...state,
        headerbanners_loading: false
      };
    case GET_ALL_HEADERBANNERS_STATED:
      return {
        ...state,
        all_headerbanners_loading: true,
        all_headerbanners: null
      };
    case GET_ALL_HEADERBANNERS:
      return {
        ...state,
        all_headerbanners: payload
      };
    case GET_ALL_HEADERBANNERS_ENDED:
      return {
        ...state,
        all_headerbanners_loading: false
      };

    case ADD_HEADERBANNER_STATED:
      return {
        ...state,
        headerbanner_message: null,
        add_headerbanner_loading: true
      };
    case ADD_HEADERBANNER:
      return {
        ...state,
        headerbanner_message: payload
      };
    case ADD_HEADERBANNER_ENDED:
      return {
        ...state,
        add_headerbanner_loading: false
      };
    case GET_HEADERBANNER_STATED:
      return {
        ...state,
        headerbanner: null,
        headerbanner_loading: true
      };
    case GET_HEADERBANNER:
      return {
        ...state,
        headerbanner: payload
      };
    case GET_HEADERBANNER_ENDED:
      return {
        ...state,
        headerbanner_loading: false
      };
    case EDIT_HEADERBANNER_STATED:
      return {
        ...state,
        headerbanner_message: null,
        edit_headerbanner_loading: true
      };
    case EDIT_HEADERBANNER:
      return {
        ...state,
        headerbanner_message: payload
      };
    case EDIT_HEADERBANNER_ENDED:
      return {
        ...state,
        edit_headerbanner_loading: false
      };

    default:
      return state;
  }
};
