export const GET_MODALS_STATED = "GET_MODALS_STATED";
export const GET_MODALS = "GET_MODALS";
export const GET_MODALS_ENDED = "GET_MODALS_ENDED";
export const ADD_MODAL_STATED = "ADD_MODAL_STARTED";
export const ADD_MODAL = "ADD_MODAL";
export const ADD_MODAL_ENDED = "ADD_MODAL_ENDED";
export const EDIT_MODAL_STATED = "EDIT_MODAL_STATED";
export const EDIT_MODAL = "EDIT_MODAL";
export const EDIT_MODAL_ENDED = "EDIT_MODAL_ENDED";
export const GET_MODAL = "GET_MODAL";
export const GET_MODAL_STATED = "GET_MODAL_STATED";
export const GET_MODAL_ENDED = "GET_MODAL_ENDED";
export const RESET_MODAL = "RESET_MODAL";
export const ERROR_MODAL = "ERROR_MODAL";
export const GET_ALL_MODALS_STATED = "GET_ALL_MODALS_STATED";
export const GET_ALL_MODALS = "GET_ALL_MODALS";
export const GET_ALL_MODALS_ENDED = "GET_ALL_MODALS_ENDED";
