export const PAGE_TITLE = 'Casting Banners'
export const PAGE_SINGLE_TITLE = 'Casting Banner'
export const LINK_URL = 'casting-banners'
export const inputFields = {
  image: {
    type: 'file',
    required: false,
    title: 'Image (1200*800)',
    inputType: '',
    options: '',
    field: '',
  },
}
export const initialValues = {
  screen_name: '',
}

export const view_all_table = [{ name: 'Image', value: 'image', image: true }]

export const SIDEBAR_OPTIONS = [
  {
    id: 'name',
    field: 'name',
    label: 'Name',
    type: 'string',
    search_type: 'search',
    inputType: 'text',
    condition: '',
  },
]
