import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addApplicant,
  getApplicants,
  getApplicant,
  editApplicant,
  deleteApplicant,
  getAllApplicants,
} from "../../store/actions/applicant_action";
import _debounce from "lodash/debounce";
import { useSelectAllJob } from "./UseJob";

// Get All Data
export const useAllApplicants = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.applicant);
  const [pageNumber, setPageNumber] = useState(1);
  const [deleteEntry, setDeleteEntry] = useState(null);

  useEffect(() => {
    if (deleteEntry) {
      dispatch(deleteApplicant(deleteEntry));
    }
    allQuery();
  }, [deleteEntry, pageNumber, window.location.search]);
  const allQuery = useCallback(
    _debounce(() => {
      dispatch(
        getApplicants({
          pageNumber,
        })
      );
    }, 1000),
    []
  );

  useEffect(() => {
    setPageNumber(1);
  }, [window.location.search]);

  const deleteBtnClicked = async (id) => {
    setDeleteEntry(id);
  };

  return [data, setPageNumber, deleteBtnClicked];
};

// Get Single Data
export const useSingleApplicant = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.applicant);
  useEffect(() => {
    dispatch(getApplicant(id));
  }, [id]);
  return [data];
};
// Add Data
export const useCreateApplicant = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.applicant);
  const addData = async (data) => {
    await dispatch(addApplicant(data));
  };
  return [data, addData];
};
export const useUpdateApplicant = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.applicant);
  const updateData = async (id, data) => {
    await dispatch(editApplicant(id, data));
  };
  return [updateData];
};

export const useSelectAllApplicant = () => {
  const dispatch = useDispatch();
  const [term, setTerm] = useState("");
  const [value, setValue] = useState("");
  const data = useSelector((state) => state.applicant);
  useEffect(() => {
    dispatch(getAllApplicants({ term, value }));
  }, [term, value]);
  return [data, setTerm, setValue];
};

export const useGetDropdownOptions = () => {
  const [job, setJobSearchField, setJobSearchValue] = useSelectAllJob();

  const [dropdownOptions, setDropdownOptions] = useState({});
  useEffect(() => {
    if (job && job.all_jobs) {
      const newData = job.all_jobs.map((item) => {
        return { label: item.title, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, job: newData });
    }
  }, [job]);
  const loadOptions = async (inputValue, callback, field) => {
    if (field == "job") {
      await setJobSearchField("name");
      await setJobSearchValue(inputValue);
      callback(dropdownOptions.job);
    }
  };

  return [dropdownOptions, loadOptions];
};
