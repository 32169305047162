export const MENU = [
  {
    label: "Dashboard",
    link: "/dashboard",
    roles: ["SUPER ADMIN"],
  },
  {
    label: "Products",
    link: "#",
    roles: ["SUPER ADMIN"],
    menu: [
      {
        label: "Category",
        link: "/categories",
        roles: ["SUPER ADMIN"],
      },
      {
        label: "Video Category",
        link: "/video-categories",
        roles: ["SUPER ADMIN"],
      },
      {
        label: "Video Courses",
        link: "/video-courses",
        roles: ["SUPER ADMIN"],
      },
      {
        label: "Study Material",
        link: "/study-materials",
        roles: ["SUPER ADMIN"],
      },
      {
        label: "Portfolios",
        link: "/portfolios",
        roles: ["SUPER ADMIN"],
      },
      {
        label: "Celebrities",
        link: "/celebritys",
        roles: ["SUPER ADMIN"],
      },
    ],
  },
  {
    label: "Contacts",
    link: "#",
    roles: ["SUPER ADMIN"],
    menu: [
      {
        label: "Contacts",
        link: "/contacts",
        roles: ["SUPER ADMIN"],
      },
      {
        label: "Newsletters",
        link: "/newsletters",
        roles: ["SUPER ADMIN"],
      },
      {
        label: "Jobs",
        link: "/jobs",
        roles: ["SUPER ADMIN"],
      },
      {
        label: "Applicants",
        link: "/applicants",
        roles: ["SUPER ADMIN"],
      },
    ],
  },
  {
    label: "Order",
    link: "#",
    roles: ["SUPER ADMIN"],
    menu: [
      {
        label: "Order",
        link: "/orders",
        roles: ["SUPER ADMIN"],
      },
      {
        label: "Coupons",
        link: "/coupons",
        roles: ["SUPER ADMIN"],
      },
      {
        label: "Customers",
        link: "/customers",
        roles: ["SUPER ADMIN"],
      },
    ],
  },
  {
    label: "Banners",
    link: "#",
    roles: ["SUPER ADMIN"],
    menu: [
      {
        label: "Casting Banner",
        link: "/casting-banners",
        roles: ["SUPER ADMIN"],
      },
      {
        label: "Header Banners",
        link: "/headerbanners",
        roles: ["SUPER ADMIN"],
      },
      {
        label: "Agency Banners",
        link: "/agency-banners",
        roles: ["SUPER ADMIN"],
      },
      {
        label: "Middle Banners",
        link: "/middle-banners",
        roles: ["SUPER ADMIN"],
      },
    ],
  },

  {
    label: "Modals",
    link: "/modals",
    roles: ["SUPER ADMIN"],
  },
];
