import React, { useEffect } from "react";
import "./assets/css/bootstrap.min.css";
import "./assets/css/app.min.css";
import "./assets/css/custom.css";
import "./assets/css/wysiwyg.css";
import "./assets/css/responsive.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/store";
import Login from "./containers/login/Login";
import { loadUser } from "./store/actions/auth";
import setAuthToken from "./domain/setAuthToken";
import BeforeLoginRoutes from "./shared/Routes/BeforeLoginRoutes";
import PrivateRoutes from "./shared/Routes/PrivateRoutes";
import Dashboard from "./containers/dashboard/Dashboard";
import PageNotFound from "./containers/notfound/PageNotFound";
import Profile from "./containers/profile/Profile";
// import setAuthToken from "./domain/setAuthToken";
import AddCategory from "./containers/categorys/AddCategory";
import AllCategorys from "./containers/categorys/AllCategorys";
import ViewCategory from "./containers/categorys/ViewCategory";
import EditCategory from "./containers/categorys/EditCategory";

import AddAgencybanner from "./containers/agencybanners/AddAgencybanner";
import AllAgencybanners from "./containers/agencybanners/AllAgencybanners";
import ViewAgencybanner from "./containers/agencybanners/ViewAgencybanner";
import EditAgencybanner from "./containers/agencybanners/EditAgencybanner";

import AddApplicant from "./containers/applicants/AddApplicant";
import AllApplicants from "./containers/applicants/AllApplicants";
import ViewApplicant from "./containers/applicants/ViewApplicant";
import EditApplicant from "./containers/applicants/EditApplicant";

import AddCastingbanner from "./containers/castingbanners/AddCastingbanner";
import AllCastingbanners from "./containers/castingbanners/AllCastingbanners";
import ViewCastingbanner from "./containers/castingbanners/ViewCastingbanner";
import EditCastingbanner from "./containers/castingbanners/EditCastingbanner";

import AddHeaderbanner from "./containers/headerbanners/AddHeaderbanner";
import AllHeaderbanners from "./containers/headerbanners/AllHeaderbanners";
import ViewHeaderbanner from "./containers/headerbanners/ViewHeaderbanner";
import EditHeaderbanner from "./containers/headerbanners/EditHeaderbanner";

import AddMiddlebanner from "./containers/middlebanners/AddMiddlebanner";
import AllMiddlebanners from "./containers/middlebanners/AllMiddlebanners";
import ViewMiddlebanner from "./containers/middlebanners/ViewMiddlebanner";
import EditMiddlebanner from "./containers/middlebanners/EditMiddlebanner";

import AddNewsletter from "./containers/newsletters/AddNewsletter";
import AllNewsletters from "./containers/newsletters/AllNewsletters";
import ViewNewsletter from "./containers/newsletters/ViewNewsletter";
import EditNewsletter from "./containers/newsletters/EditNewsletter";

import AddContact from "./containers/contacts/AddContact";
import AllContacts from "./containers/contacts/AllContacts";
import ViewContact from "./containers/contacts/ViewContact";
import EditContact from "./containers/contacts/EditContact";

import AddCoupon from "./containers/coupons/AddCoupon";
import AllCoupons from "./containers/coupons/AllCoupons";
import ViewCoupon from "./containers/coupons/ViewCoupon";
import EditCoupon from "./containers/coupons/EditCoupon";

import AddJob from "./containers/jobs/AddJob";
import AllJobs from "./containers/jobs/AllJobs";
import ViewJob from "./containers/jobs/ViewJob";
import EditJob from "./containers/jobs/EditJob";

import AddVideocategory from "./containers/videocategorys/AddVideocategory";
import AllVideocategorys from "./containers/videocategorys/AllVideocategorys";
import ViewVideocategory from "./containers/videocategorys/ViewVideocategory";
import EditVideocategory from "./containers/videocategorys/EditVideocategory";

import AddVideocourse from "./containers/videocourses/AddVideocourse";
import AllVideocourses from "./containers/videocourses/AllVideocourses";
import ViewVideocourse from "./containers/videocourses/ViewVideocourse";
import EditVideocourse from "./containers/videocourses/EditVideocourse";

import AddStudymaterial from "./containers/studymaterials/AddStudymaterial";
import AllStudymaterials from "./containers/studymaterials/AllStudymaterials";
import ViewStudymaterial from "./containers/studymaterials/ViewStudymaterial";
import EditStudymaterial from "./containers/studymaterials/EditStudymaterial";

import AddOrder from "./containers/orders/AddOrder";
import AllOrders from "./containers/orders/AllOrders";
import ViewOrder from "./containers/orders/ViewOrder";
import EditOrder from "./containers/orders/EditOrder";

import AddCustomer from "./containers/customers/AddCustomer";
import AllCustomers from "./containers/customers/AllCustomers";
import ViewCustomer from "./containers/customers/ViewCustomer";
import EditCustomer from "./containers/customers/EditCustomer";

import AddPortfolio from "./containers/portfolios/AddPortfolio";
import AllPortfolios from "./containers/portfolios/AllPortfolios";
import ViewPortfolio from "./containers/portfolios/ViewPortfolio";
import EditPortfolio from "./containers/portfolios/EditPortfolio";

import AddModal from "./containers/modals/AddModal";
import AllModals from "./containers/modals/AllModals";
import ViewModal from "./containers/modals/ViewModal";
import EditModal from "./containers/modals/EditModal";

import AddCelebrity from "./containers/celebritys/AddCelebrity";
import AllCelebritys from "./containers/celebritys/AllCelebritys";
import ViewCelebrity from "./containers/celebritys/ViewCelebrity";
import EditCelebrity from "./containers/celebritys/EditCelebrity";

function App() {
  useEffect(() => {
    //First we have to bring(get that) token, which is saved in local storage
    const token = localStorage.getItem("token");
    //then we will pass that token in setAuthToken method
    setAuthToken(token);
    store.dispatch(loadUser());
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <BeforeLoginRoutes exact path="/" component={Login} />
          <PrivateRoutes exact path="/dashboard" component={Dashboard} />
          <PrivateRoutes exact path="/profile" component={Profile} />

          <PrivateRoutes exact path="/categories" component={AllCategorys} />
          <PrivateRoutes exact path="/categories/add" component={AddCategory} />
          <PrivateRoutes
            exact
            path="/categories/:id/view"
            component={ViewCategory}
          />
          <PrivateRoutes
            exact
            path="/categories/:id/edit"
            component={EditCategory}
          />

          <PrivateRoutes
            exact
            path="/agency-banners"
            component={AllAgencybanners}
          />
          <PrivateRoutes
            exact
            path="/agency-banners/add"
            component={AddAgencybanner}
          />
          <PrivateRoutes
            exact
            path="/agency-banners/:id/view"
            component={ViewAgencybanner}
          />
          <PrivateRoutes
            exact
            path="/agency-banners/:id/edit"
            component={EditAgencybanner}
          />

          <PrivateRoutes exact path="/applicants" component={AllApplicants} />
          <PrivateRoutes
            exact
            path="/applicants/add"
            component={AddApplicant}
          />
          <PrivateRoutes
            exact
            path="/applicants/:id/view"
            component={ViewApplicant}
          />
          <PrivateRoutes
            exact
            path="/applicants/:id/edit"
            component={EditApplicant}
          />

          <PrivateRoutes
            exact
            path="/casting-banners"
            component={AllCastingbanners}
          />
          <PrivateRoutes
            exact
            path="/casting-banners/add"
            component={AddCastingbanner}
          />
          <PrivateRoutes
            exact
            path="/casting-banners/:id/view"
            component={ViewCastingbanner}
          />
          <PrivateRoutes
            exact
            path="/casting-banners/:id/edit"
            component={EditCastingbanner}
          />

          <PrivateRoutes
            exact
            path="/headerbanners"
            component={AllHeaderbanners}
          />
          <PrivateRoutes
            exact
            path="/headerbanners/add"
            component={AddHeaderbanner}
          />
          <PrivateRoutes
            exact
            path="/headerbanners/:id/view"
            component={ViewHeaderbanner}
          />
          <PrivateRoutes
            exact
            path="/headerbanners/:id/edit"
            component={EditHeaderbanner}
          />

          <PrivateRoutes
            exact
            path="/middle-banners"
            component={AllMiddlebanners}
          />
          <PrivateRoutes
            exact
            path="/middle-banners/add"
            component={AddMiddlebanner}
          />
          <PrivateRoutes
            exact
            path="/middle-banners/:id/view"
            component={ViewMiddlebanner}
          />
          <PrivateRoutes
            exact
            path="/middle-banners/:id/edit"
            component={EditMiddlebanner}
          />

          <PrivateRoutes exact path="/newsletters" component={AllNewsletters} />
          <PrivateRoutes
            exact
            path="/newsletters/add"
            component={AddNewsletter}
          />
          <PrivateRoutes
            exact
            path="/newsletters/:id/view"
            component={ViewNewsletter}
          />
          <PrivateRoutes
            exact
            path="/newsletters/:id/edit"
            component={EditNewsletter}
          />

          <PrivateRoutes exact path="/contacts" component={AllContacts} />
          <PrivateRoutes exact path="/contacts/add" component={AddContact} />
          <PrivateRoutes
            exact
            path="/contacts/:id/view"
            component={ViewContact}
          />
          <PrivateRoutes
            exact
            path="/contacts/:id/edit"
            component={EditContact}
          />

          <PrivateRoutes exact path="/coupons" component={AllCoupons} />
          <PrivateRoutes exact path="/coupons/add" component={AddCoupon} />
          <PrivateRoutes
            exact
            path="/coupons/:id/view"
            component={ViewCoupon}
          />
          <PrivateRoutes
            exact
            path="/coupons/:id/edit"
            component={EditCoupon}
          />

          <PrivateRoutes exact path="/jobs" component={AllJobs} />
          <PrivateRoutes exact path="/jobs/add" component={AddJob} />
          <PrivateRoutes exact path="/jobs/:id/view" component={ViewJob} />
          <PrivateRoutes exact path="/jobs/:id/edit" component={EditJob} />

          <PrivateRoutes
            exact
            path="/video-categories"
            component={AllVideocategorys}
          />
          <PrivateRoutes
            exact
            path="/video-categories/add"
            component={AddVideocategory}
          />
          <PrivateRoutes
            exact
            path="/video-categories/:id/view"
            component={ViewVideocategory}
          />
          <PrivateRoutes
            exact
            path="/video-categories/:id/edit"
            component={EditVideocategory}
          />

          <PrivateRoutes
            exact
            path="/video-courses"
            component={AllVideocourses}
          />
          <PrivateRoutes
            exact
            path="/video-courses/add"
            component={AddVideocourse}
          />
          <PrivateRoutes
            exact
            path="/video-courses/:id/view"
            component={ViewVideocourse}
          />
          <PrivateRoutes
            exact
            path="/video-courses/:id/edit"
            component={EditVideocourse}
          />

          <PrivateRoutes
            exact
            path="/study-materials"
            component={AllStudymaterials}
          />
          <PrivateRoutes
            exact
            path="/study-materials/add"
            component={AddStudymaterial}
          />
          <PrivateRoutes
            exact
            path="/study-materials/:id/view"
            component={ViewStudymaterial}
          />
          <PrivateRoutes
            exact
            path="/study-materials/:id/edit"
            component={EditStudymaterial}
          />

          <PrivateRoutes exact path="/orders" component={AllOrders} />
          <PrivateRoutes exact path="/orders/add" component={AddOrder} />
          <PrivateRoutes exact path="/orders/:id/view" component={ViewOrder} />
          <PrivateRoutes exact path="/orders/:id/edit" component={EditOrder} />

          <PrivateRoutes exact path="/customers" component={AllCustomers} />
          <PrivateRoutes exact path="/customers/add" component={AddCustomer} />
          <PrivateRoutes
            exact
            path="/customers/:id/view"
            component={ViewCustomer}
          />
          <PrivateRoutes
            exact
            path="/customers/:id/edit"
            component={EditCustomer}
          />

          <PrivateRoutes exact path="/portfolios" component={AllPortfolios} />
          <PrivateRoutes
            exact
            path="/portfolios/add"
            component={AddPortfolio}
          />
          <PrivateRoutes
            exact
            path="/portfolios/:id/view"
            component={ViewPortfolio}
          />
          <PrivateRoutes
            exact
            path="/portfolios/:id/edit"
            component={EditPortfolio}
          />

          <PrivateRoutes exact path="/modals" component={AllModals} />
          <PrivateRoutes exact path="/modals/add" component={AddModal} />
          <PrivateRoutes exact path="/modals/:id/view" component={ViewModal} />
          <PrivateRoutes exact path="/modals/:id/edit" component={EditModal} />

          <PrivateRoutes exact path="/celebritys" component={AllCelebritys} />
          <PrivateRoutes
            exact
            path="/celebritys/add"
            component={AddCelebrity}
          />
          <PrivateRoutes
            exact
            path="/celebritys/:id/view"
            component={ViewCelebrity}
          />
          <PrivateRoutes
            exact
            path="/celebritys/:id/edit"
            component={EditCelebrity}
          />

          <Route component={PageNotFound} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
