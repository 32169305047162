export const GET_AGENCYBANNERS_STATED = "GET_AGENCYBANNERS_STATED";
export const GET_AGENCYBANNERS = "GET_AGENCYBANNERS";
export const GET_AGENCYBANNERS_ENDED = "GET_AGENCYBANNERS_ENDED";
export const ADD_AGENCYBANNER_STATED = "ADD_AGENCYBANNER_STARTED";
export const ADD_AGENCYBANNER = "ADD_AGENCYBANNER";
export const ADD_AGENCYBANNER_ENDED = "ADD_AGENCYBANNER_ENDED";
export const EDIT_AGENCYBANNER_STATED = "EDIT_AGENCYBANNER_STATED";
export const EDIT_AGENCYBANNER = "EDIT_AGENCYBANNER";
export const EDIT_AGENCYBANNER_ENDED = "EDIT_AGENCYBANNER_ENDED";
export const GET_AGENCYBANNER = "GET_AGENCYBANNER";
export const GET_AGENCYBANNER_STATED = "GET_AGENCYBANNER_STATED";
export const GET_AGENCYBANNER_ENDED = "GET_AGENCYBANNER_ENDED";
export const RESET_AGENCYBANNER = "RESET_AGENCYBANNER";
export const ERROR_AGENCYBANNER = "ERROR_AGENCYBANNER";
export const GET_ALL_AGENCYBANNERS_STATED = "GET_ALL_AGENCYBANNERS_STATED";
export const GET_ALL_AGENCYBANNERS = "GET_ALL_AGENCYBANNERS";
export const GET_ALL_AGENCYBANNERS_ENDED = "GET_ALL_AGENCYBANNERS_ENDED";
