import api from "../../domain/api";
import {
  GET_CASTINGBANNERS_STATED,
  GET_CASTINGBANNERS,
  GET_CASTINGBANNERS_ENDED,
  ADD_CASTINGBANNER_STATED,
  ADD_CASTINGBANNER,
  ADD_CASTINGBANNER_ENDED,
  EDIT_CASTINGBANNER_STATED,
  EDIT_CASTINGBANNER,
  EDIT_CASTINGBANNER_ENDED,
  GET_CASTINGBANNER_STATED,
  GET_CASTINGBANNER,
  GET_CASTINGBANNER_ENDED,
  GET_ALL_CASTINGBANNERS_STATED,
  GET_ALL_CASTINGBANNERS,
  GET_ALL_CASTINGBANNERS_ENDED,
} from "../types/castingbanner_type";
import * as qs from "qs";
import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";

export const addCastingbanner = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_CASTINGBANNER_STATED,
    });
    const { data } = await api.post(`/castingbanners`, formData);
    dispatch({
      type: ADD_CASTINGBANNER,
      payload: data,
    });
    dispatch({
      type: ADD_CASTINGBANNER_ENDED,
    });
  } catch (error) {
    dispatch({
      type: ADD_CASTINGBANNER_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getCastingbanners =
  ({ pageNumber = ""}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_CASTINGBANNERS_STATED,
      });
      const queryParams = qs.parse(window.location.search.replace("?", ""));
      const query = qs.stringify(queryParams, {
        encodeValuesOnly: true, // prettify url
      });

      const { data } = await api.get(
        `/castingbanners?&pageNumber=${pageNumber}&${query}`
      );

      dispatch({
        type: GET_CASTINGBANNERS,
        payload: data,
      });
      dispatch({
        type: GET_CASTINGBANNERS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_CASTINGBANNERS_ENDED,
      });
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const getCastingbanner = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_CASTINGBANNER_STATED,
    });
    const { data } = await api.get(`/castingbanners/${id}`);

    dispatch({
      type: GET_CASTINGBANNER,
      payload: data,
    });
    dispatch({
      type: GET_CASTINGBANNER_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_CASTINGBANNER_STATED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editCastingbanner = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_CASTINGBANNER_STATED,
    });
    const { data } = await api.put(`/castingbanners/${id}`, formData);
    dispatch({
      type: EDIT_CASTINGBANNER,
      payload: data,
    });
    dispatch({
      type: EDIT_CASTINGBANNER_ENDED,
    });
  } catch (error) {
    dispatch({
      type: EDIT_CASTINGBANNER_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteCastingbanner = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/castingbanners/${id}`);
    dispatch(setAlert("Castingbanner Deleted Successfully", "success"));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllCastingbanners = ({ term, value }) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_CASTINGBANNERS_STATED,
    });
    const { data } = await api.get(`/castingbanners/all?term=${term}&value=${value}`);

    dispatch({
      type: GET_ALL_CASTINGBANNERS,
      payload: data,
    });
    dispatch({
      type: GET_ALL_CASTINGBANNERS_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_CASTINGBANNERS_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};

export const handleErrorLocal = () => async (dispatch) => {};
