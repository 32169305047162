import {
  GET_MODALS_STATED,
  GET_MODALS,
  GET_MODALS_ENDED,
  ADD_MODAL_STATED,
  ADD_MODAL,
  ADD_MODAL_ENDED,
  EDIT_MODAL_STATED,
  EDIT_MODAL,
  EDIT_MODAL_ENDED,
  GET_MODAL_STATED,
  GET_MODAL,
  GET_MODAL_ENDED,
  GET_ALL_MODALS_STATED,
  GET_ALL_MODALS,
  GET_ALL_MODALS_ENDED
} from "../types/modal_type";

const initialState = {
  modals_loading: true,
  modals: null,
  page: null,
  pages: null,
  total_modals: 0,

  modal: null,
  modal_loading: null,

  loading: true,

  modal_message: null,
  all_modals: null,
  all_modals_loading: null,
  add_modal_loading: true,
  edit_modal_loading: true
};

export const modal_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_MODALS_STATED:
      return {
        ...state,
        modals: null,
        pages: null,
        page: null,
        total_modals: 0,
        modals_loading: true
      };
    case GET_MODALS:
      return {
        ...state,
        modals: payload.modals,
        pages: payload.pages,
        page: payload.page,
        total_modals: payload.count
      };
    case GET_MODALS_ENDED:
      return {
        ...state,
        modals_loading: false
      };
    case GET_ALL_MODALS_STATED:
      return {
        ...state,
        all_modals_loading: true,
        all_modals: null
      };
    case GET_ALL_MODALS:
      return {
        ...state,
        all_modals: payload
      };
    case GET_ALL_MODALS_ENDED:
      return {
        ...state,
        all_modals_loading: false
      };

    case ADD_MODAL_STATED:
      return {
        ...state,
        modal_message: null,
        add_modal_loading: true
      };
    case ADD_MODAL:
      return {
        ...state,
        modal_message: payload
      };
    case ADD_MODAL_ENDED:
      return {
        ...state,
        add_modal_loading: false
      };
    case GET_MODAL_STATED:
      return {
        ...state,
        modal: null,
        modal_loading: true
      };
    case GET_MODAL:
      return {
        ...state,
        modal: payload
      };
    case GET_MODAL_ENDED:
      return {
        ...state,
        modal_loading: false
      };
    case EDIT_MODAL_STATED:
      return {
        ...state,
        modal_message: null,
        edit_modal_loading: true
      };
    case EDIT_MODAL:
      return {
        ...state,
        modal_message: payload
      };
    case EDIT_MODAL_ENDED:
      return {
        ...state,
        edit_modal_loading: false
      };

    default:
      return state;
  }
};
