export const GET_APPLICANTS_STATED = "GET_APPLICANTS_STATED";
export const GET_APPLICANTS = "GET_APPLICANTS";
export const GET_APPLICANTS_ENDED = "GET_APPLICANTS_ENDED";
export const ADD_APPLICANT_STATED = "ADD_APPLICANT_STARTED";
export const ADD_APPLICANT = "ADD_APPLICANT";
export const ADD_APPLICANT_ENDED = "ADD_APPLICANT_ENDED";
export const EDIT_APPLICANT_STATED = "EDIT_APPLICANT_STATED";
export const EDIT_APPLICANT = "EDIT_APPLICANT";
export const EDIT_APPLICANT_ENDED = "EDIT_APPLICANT_ENDED";
export const GET_APPLICANT = "GET_APPLICANT";
export const GET_APPLICANT_STATED = "GET_APPLICANT_STATED";
export const GET_APPLICANT_ENDED = "GET_APPLICANT_ENDED";
export const RESET_APPLICANT = "RESET_APPLICANT";
export const ERROR_APPLICANT = "ERROR_APPLICANT";
export const GET_ALL_APPLICANTS_STATED = "GET_ALL_APPLICANTS_STATED";
export const GET_ALL_APPLICANTS = "GET_ALL_APPLICANTS";
export const GET_ALL_APPLICANTS_ENDED = "GET_ALL_APPLICANTS_ENDED";
