import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addOrder,
  getOrders,
  getOrder,
  editOrder,
  deleteOrder,
  getAllOrders,
} from "../../store/actions/order_action";
import _debounce from "lodash/debounce";
import { useSelectAllVideocourse } from "./UseVideocourse";
import { useSelectAllCustomer } from "./UseCustomer";

// Get All Data
export const useAllOrders = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.order);
  const [pageNumber, setPageNumber] = useState(1);
  const [deleteEntry, setDeleteEntry] = useState(null);

  useEffect(() => {
    if (deleteEntry) {
      dispatch(deleteOrder(deleteEntry));
    }
    allQuery();
  }, [deleteEntry, pageNumber, window.location.search]);
  const allQuery = useCallback(
    _debounce(() => {
      dispatch(
        getOrders({
          pageNumber,
        })
      );
    }, 1000),
    []
  );

  useEffect(() => {
    setPageNumber(1);
  }, [window.location.search]);

  const deleteBtnClicked = async (id) => {
    setDeleteEntry(id);
  };

  return [data, setPageNumber, deleteBtnClicked];
};

// Get Single Data
export const useSingleOrder = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.order);
  useEffect(() => {
    dispatch(getOrder(id));
  }, [id]);
  return [data];
};
// Add Data
export const useCreateOrder = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.order);
  const addData = async (data) => {
    await dispatch(addOrder(data));
  };
  return [data, addData];
};
export const useUpdateOrder = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.order);
  const updateData = async (id, data) => {
    await dispatch(editOrder(id, data));
  };
  return [updateData];
};

export const useSelectAllOrder = () => {
  const dispatch = useDispatch();
  const [term, setTerm] = useState("");
  const [value, setValue] = useState("");
  const data = useSelector((state) => state.order);
  useEffect(() => {
    dispatch(getAllOrders({ term, value }));
  }, [term, value]);
  return [data, setTerm, setValue];
};

export const useGetDropdownOptions = () => {
  const [videocourse, setVideoCourseSearchField, setVideoCourseSearchValue] =
    useSelectAllVideocourse();
  const [customer, setCustomerSearchField, setCustomerSearchValue] =
    useSelectAllCustomer();

  const [dropdownOptions, setDropdownOptions] = useState({});
  useEffect(() => {
    if (videocourse && videocourse.all_videocourses) {
      const newData = videocourse.all_videocourses.map((item) => {
        return { label: item.title, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, courses: newData });
    }
  }, [videocourse]);
  useEffect(() => {
    if (customer && customer.all_customers) {
      const newData = customer.all_customers.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, customer: newData });
    }
  }, [customer]);
  const loadOptions = async (inputValue, callback, field) => {
    if (field == "course") {
      await setVideoCourseSearchField("name");
      await setVideoCourseSearchValue(inputValue);
      callback(dropdownOptions.courses);
    }
    if (field == "customer") {
      await setVideoCourseSearchField("name");
      await setVideoCourseSearchValue(inputValue);
      callback(dropdownOptions.customer);
    }
  };

  return [dropdownOptions, loadOptions];
};
