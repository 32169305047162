import api from "../../domain/api";
import {
  GET_MIDDLEBANNERS_STATED,
  GET_MIDDLEBANNERS,
  GET_MIDDLEBANNERS_ENDED,
  ADD_MIDDLEBANNER_STATED,
  ADD_MIDDLEBANNER,
  ADD_MIDDLEBANNER_ENDED,
  EDIT_MIDDLEBANNER_STATED,
  EDIT_MIDDLEBANNER,
  EDIT_MIDDLEBANNER_ENDED,
  GET_MIDDLEBANNER_STATED,
  GET_MIDDLEBANNER,
  GET_MIDDLEBANNER_ENDED,
  GET_ALL_MIDDLEBANNERS_STATED,
  GET_ALL_MIDDLEBANNERS,
  GET_ALL_MIDDLEBANNERS_ENDED,
} from "../types/middlebanner_type";
import * as qs from "qs";
import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";

export const addMiddlebanner = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_MIDDLEBANNER_STATED,
    });
    const { data } = await api.post(`/middlebanners`, formData);
    dispatch({
      type: ADD_MIDDLEBANNER,
      payload: data,
    });
    dispatch({
      type: ADD_MIDDLEBANNER_ENDED,
    });
  } catch (error) {
    dispatch({
      type: ADD_MIDDLEBANNER_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getMiddlebanners =
  ({ pageNumber = ""}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_MIDDLEBANNERS_STATED,
      });
      const queryParams = qs.parse(window.location.search.replace("?", ""));
      const query = qs.stringify(queryParams, {
        encodeValuesOnly: true, // prettify url
      });

      const { data } = await api.get(
        `/middlebanners?&pageNumber=${pageNumber}&${query}`
      );

      dispatch({
        type: GET_MIDDLEBANNERS,
        payload: data,
      });
      dispatch({
        type: GET_MIDDLEBANNERS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_MIDDLEBANNERS_ENDED,
      });
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const getMiddlebanner = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_MIDDLEBANNER_STATED,
    });
    const { data } = await api.get(`/middlebanners/${id}`);

    dispatch({
      type: GET_MIDDLEBANNER,
      payload: data,
    });
    dispatch({
      type: GET_MIDDLEBANNER_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_MIDDLEBANNER_STATED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editMiddlebanner = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_MIDDLEBANNER_STATED,
    });
    const { data } = await api.put(`/middlebanners/${id}`, formData);
    dispatch({
      type: EDIT_MIDDLEBANNER,
      payload: data,
    });
    dispatch({
      type: EDIT_MIDDLEBANNER_ENDED,
    });
  } catch (error) {
    dispatch({
      type: EDIT_MIDDLEBANNER_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteMiddlebanner = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/middlebanners/${id}`);
    dispatch(setAlert("Middlebanner Deleted Successfully", "success"));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllMiddlebanners = ({ term, value }) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_MIDDLEBANNERS_STATED,
    });
    const { data } = await api.get(`/middlebanners/all?term=${term}&value=${value}`);

    dispatch({
      type: GET_ALL_MIDDLEBANNERS,
      payload: data,
    });
    dispatch({
      type: GET_ALL_MIDDLEBANNERS_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_MIDDLEBANNERS_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};

export const handleErrorLocal = () => async (dispatch) => {};
