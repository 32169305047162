export const GET_PORTFOLIOS_STATED = "GET_PORTFOLIOS_STATED";
export const GET_PORTFOLIOS = "GET_PORTFOLIOS";
export const GET_PORTFOLIOS_ENDED = "GET_PORTFOLIOS_ENDED";
export const ADD_PORTFOLIO_STATED = "ADD_PORTFOLIO_STARTED";
export const ADD_PORTFOLIO = "ADD_PORTFOLIO";
export const ADD_PORTFOLIO_ENDED = "ADD_PORTFOLIO_ENDED";
export const EDIT_PORTFOLIO_STATED = "EDIT_PORTFOLIO_STATED";
export const EDIT_PORTFOLIO = "EDIT_PORTFOLIO";
export const EDIT_PORTFOLIO_ENDED = "EDIT_PORTFOLIO_ENDED";
export const GET_PORTFOLIO = "GET_PORTFOLIO";
export const GET_PORTFOLIO_STATED = "GET_PORTFOLIO_STATED";
export const GET_PORTFOLIO_ENDED = "GET_PORTFOLIO_ENDED";
export const RESET_PORTFOLIO = "RESET_PORTFOLIO";
export const ERROR_PORTFOLIO = "ERROR_PORTFOLIO";
export const GET_ALL_PORTFOLIOS_STATED = "GET_ALL_PORTFOLIOS_STATED";
export const GET_ALL_PORTFOLIOS = "GET_ALL_PORTFOLIOS";
export const GET_ALL_PORTFOLIOS_ENDED = "GET_ALL_PORTFOLIOS_ENDED";
