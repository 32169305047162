import {
  GET_JOBS_STATED,
  GET_JOBS,
  GET_JOBS_ENDED,
  ADD_JOB_STATED,
  ADD_JOB,
  ADD_JOB_ENDED,
  EDIT_JOB_STATED,
  EDIT_JOB,
  EDIT_JOB_ENDED,
  GET_JOB_STATED,
  GET_JOB,
  GET_JOB_ENDED,
  GET_ALL_JOBS_STATED,
  GET_ALL_JOBS,
  GET_ALL_JOBS_ENDED
} from "../types/job_type";

const initialState = {
  jobs_loading: true,
  jobs: null,
  page: null,
  pages: null,
  total_jobs: 0,

  job: null,
  job_loading: null,

  loading: true,

  job_message: null,
  all_jobs: null,
  all_jobs_loading: null,
  add_job_loading: true,
  edit_job_loading: true
};

export const job_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_JOBS_STATED:
      return {
        ...state,
        jobs: null,
        pages: null,
        page: null,
        total_jobs: 0,
        jobs_loading: true
      };
    case GET_JOBS:
      return {
        ...state,
        jobs: payload.jobs,
        pages: payload.pages,
        page: payload.page,
        total_jobs: payload.count
      };
    case GET_JOBS_ENDED:
      return {
        ...state,
        jobs_loading: false
      };
    case GET_ALL_JOBS_STATED:
      return {
        ...state,
        all_jobs_loading: true,
        all_jobs: null
      };
    case GET_ALL_JOBS:
      return {
        ...state,
        all_jobs: payload
      };
    case GET_ALL_JOBS_ENDED:
      return {
        ...state,
        all_jobs_loading: false
      };

    case ADD_JOB_STATED:
      return {
        ...state,
        job_message: null,
        add_job_loading: true
      };
    case ADD_JOB:
      return {
        ...state,
        job_message: payload
      };
    case ADD_JOB_ENDED:
      return {
        ...state,
        add_job_loading: false
      };
    case GET_JOB_STATED:
      return {
        ...state,
        job: null,
        job_loading: true
      };
    case GET_JOB:
      return {
        ...state,
        job: payload
      };
    case GET_JOB_ENDED:
      return {
        ...state,
        job_loading: false
      };
    case EDIT_JOB_STATED:
      return {
        ...state,
        job_message: null,
        edit_job_loading: true
      };
    case EDIT_JOB:
      return {
        ...state,
        job_message: payload
      };
    case EDIT_JOB_ENDED:
      return {
        ...state,
        edit_job_loading: false
      };

    default:
      return state;
  }
};
