export const GET_HEADERBANNERS_STATED = "GET_HEADERBANNERS_STATED";
export const GET_HEADERBANNERS = "GET_HEADERBANNERS";
export const GET_HEADERBANNERS_ENDED = "GET_HEADERBANNERS_ENDED";
export const ADD_HEADERBANNER_STATED = "ADD_HEADERBANNER_STARTED";
export const ADD_HEADERBANNER = "ADD_HEADERBANNER";
export const ADD_HEADERBANNER_ENDED = "ADD_HEADERBANNER_ENDED";
export const EDIT_HEADERBANNER_STATED = "EDIT_HEADERBANNER_STATED";
export const EDIT_HEADERBANNER = "EDIT_HEADERBANNER";
export const EDIT_HEADERBANNER_ENDED = "EDIT_HEADERBANNER_ENDED";
export const GET_HEADERBANNER = "GET_HEADERBANNER";
export const GET_HEADERBANNER_STATED = "GET_HEADERBANNER_STATED";
export const GET_HEADERBANNER_ENDED = "GET_HEADERBANNER_ENDED";
export const RESET_HEADERBANNER = "RESET_HEADERBANNER";
export const ERROR_HEADERBANNER = "ERROR_HEADERBANNER";
export const GET_ALL_HEADERBANNERS_STATED = "GET_ALL_HEADERBANNERS_STATED";
export const GET_ALL_HEADERBANNERS = "GET_ALL_HEADERBANNERS";
export const GET_ALL_HEADERBANNERS_ENDED = "GET_ALL_HEADERBANNERS_ENDED";
