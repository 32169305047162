export const GET_CASTINGBANNERS_STATED = "GET_CASTINGBANNERS_STATED";
export const GET_CASTINGBANNERS = "GET_CASTINGBANNERS";
export const GET_CASTINGBANNERS_ENDED = "GET_CASTINGBANNERS_ENDED";
export const ADD_CASTINGBANNER_STATED = "ADD_CASTINGBANNER_STARTED";
export const ADD_CASTINGBANNER = "ADD_CASTINGBANNER";
export const ADD_CASTINGBANNER_ENDED = "ADD_CASTINGBANNER_ENDED";
export const EDIT_CASTINGBANNER_STATED = "EDIT_CASTINGBANNER_STATED";
export const EDIT_CASTINGBANNER = "EDIT_CASTINGBANNER";
export const EDIT_CASTINGBANNER_ENDED = "EDIT_CASTINGBANNER_ENDED";
export const GET_CASTINGBANNER = "GET_CASTINGBANNER";
export const GET_CASTINGBANNER_STATED = "GET_CASTINGBANNER_STATED";
export const GET_CASTINGBANNER_ENDED = "GET_CASTINGBANNER_ENDED";
export const RESET_CASTINGBANNER = "RESET_CASTINGBANNER";
export const ERROR_CASTINGBANNER = "ERROR_CASTINGBANNER";
export const GET_ALL_CASTINGBANNERS_STATED = "GET_ALL_CASTINGBANNERS_STATED";
export const GET_ALL_CASTINGBANNERS = "GET_ALL_CASTINGBANNERS";
export const GET_ALL_CASTINGBANNERS_ENDED = "GET_ALL_CASTINGBANNERS_ENDED";
