export const GET_MIDDLEBANNERS_STATED = "GET_MIDDLEBANNERS_STATED";
export const GET_MIDDLEBANNERS = "GET_MIDDLEBANNERS";
export const GET_MIDDLEBANNERS_ENDED = "GET_MIDDLEBANNERS_ENDED";
export const ADD_MIDDLEBANNER_STATED = "ADD_MIDDLEBANNER_STARTED";
export const ADD_MIDDLEBANNER = "ADD_MIDDLEBANNER";
export const ADD_MIDDLEBANNER_ENDED = "ADD_MIDDLEBANNER_ENDED";
export const EDIT_MIDDLEBANNER_STATED = "EDIT_MIDDLEBANNER_STATED";
export const EDIT_MIDDLEBANNER = "EDIT_MIDDLEBANNER";
export const EDIT_MIDDLEBANNER_ENDED = "EDIT_MIDDLEBANNER_ENDED";
export const GET_MIDDLEBANNER = "GET_MIDDLEBANNER";
export const GET_MIDDLEBANNER_STATED = "GET_MIDDLEBANNER_STATED";
export const GET_MIDDLEBANNER_ENDED = "GET_MIDDLEBANNER_ENDED";
export const RESET_MIDDLEBANNER = "RESET_MIDDLEBANNER";
export const ERROR_MIDDLEBANNER = "ERROR_MIDDLEBANNER";
export const GET_ALL_MIDDLEBANNERS_STATED = "GET_ALL_MIDDLEBANNERS_STATED";
export const GET_ALL_MIDDLEBANNERS = "GET_ALL_MIDDLEBANNERS";
export const GET_ALL_MIDDLEBANNERS_ENDED = "GET_ALL_MIDDLEBANNERS_ENDED";
