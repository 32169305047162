export const PAGE_TITLE = 'Categories'
export const PAGE_SINGLE_TITLE = 'Category'
export const LINK_URL = 'categories'
export const inputFields = {
  name: {
    type: 'string',
    required: true,
    title: 'Name ',
    inputType: '',
    options: '',
    field: '',
  },
  image: {
    type: 'file',
    required: false,
    title: 'Image (640*960)',
    inputType: '',
    options: '',
    field: '',
  },

  gallery: {
    type: 'gallery',
    required: false,
    title: 'Gallery (640*960)',
    inputType: '',
    options: '',
    field: '',
  },
}
export const initialValues = {
  name: '',
}

export const view_all_table = [
  { name: 'Name', value: 'name' },

  { name: 'Image', value: 'image', image: true },
]

export const SIDEBAR_OPTIONS = [
  {
    id: 'name',
    field: 'name',
    label: 'Name',
    type: 'string',
    search_type: 'search',
    inputType: 'text',
    condition: '',
  },
]
