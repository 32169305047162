export const PAGE_TITLE = "Contacts";
export const PAGE_SINGLE_TITLE = "Contact";
export const LINK_URL = "contacts";
export const inputFields = {
  name: {
    type: "string",
    required: true,
    title: "name",
    inputType: "",
    options: "",
    field: "",
  },
  email: {
    type: "string",
    required: true,
    title: "email",
    inputType: "",
    options: "",
    field: "",
  },
  phone: {
    type: "string",
    required: true,
    title: "phone",
    inputType: "",
    options: "",
    field: "",
  },
  subject: {
    type: "string",
    required: true,
    title: "subject",
    inputType: "",
    options: "",
    field: "",
  },
  message: {
    type: "text",
    required: true,
    title: "message",
    inputType: "",
    options: "",
    field: "",
  },
};
export const initialValues = {
  name: "",
};

export const view_all_table = [
  { name: "Name", value: "name" },
  { name: "Phone", value: "phone" },
  { name: "Email", value: "email" },
];

export const SIDEBAR_OPTIONS = [
  {
    id: "name",
    field: "name",
    label: "Name",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
];
