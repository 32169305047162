import api from "../../domain/api";
import {
  GET_STUDYMATERIALS_STATED,
  GET_STUDYMATERIALS,
  GET_STUDYMATERIALS_ENDED,
  ADD_STUDYMATERIAL_STATED,
  ADD_STUDYMATERIAL,
  ADD_STUDYMATERIAL_ENDED,
  EDIT_STUDYMATERIAL_STATED,
  EDIT_STUDYMATERIAL,
  EDIT_STUDYMATERIAL_ENDED,
  GET_STUDYMATERIAL_STATED,
  GET_STUDYMATERIAL,
  GET_STUDYMATERIAL_ENDED,
  GET_ALL_STUDYMATERIALS_STATED,
  GET_ALL_STUDYMATERIALS,
  GET_ALL_STUDYMATERIALS_ENDED,
} from "../types/studymaterial_type";
import * as qs from "qs";
import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";

export const addStudymaterial = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_STUDYMATERIAL_STATED,
    });
    const { data } = await api.post(`/studymaterials`, formData);
    dispatch({
      type: ADD_STUDYMATERIAL,
      payload: data,
    });
    dispatch({
      type: ADD_STUDYMATERIAL_ENDED,
    });
  } catch (error) {
    dispatch({
      type: ADD_STUDYMATERIAL_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getStudymaterials =
  ({ pageNumber = ""}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_STUDYMATERIALS_STATED,
      });
      const queryParams = qs.parse(window.location.search.replace("?", ""));
      const query = qs.stringify(queryParams, {
        encodeValuesOnly: true, // prettify url
      });

      const { data } = await api.get(
        `/studymaterials?&pageNumber=${pageNumber}&${query}`
      );

      dispatch({
        type: GET_STUDYMATERIALS,
        payload: data,
      });
      dispatch({
        type: GET_STUDYMATERIALS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_STUDYMATERIALS_ENDED,
      });
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const getStudymaterial = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_STUDYMATERIAL_STATED,
    });
    const { data } = await api.get(`/studymaterials/${id}`);

    dispatch({
      type: GET_STUDYMATERIAL,
      payload: data,
    });
    dispatch({
      type: GET_STUDYMATERIAL_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_STUDYMATERIAL_STATED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editStudymaterial = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_STUDYMATERIAL_STATED,
    });
    const { data } = await api.put(`/studymaterials/${id}`, formData);
    dispatch({
      type: EDIT_STUDYMATERIAL,
      payload: data,
    });
    dispatch({
      type: EDIT_STUDYMATERIAL_ENDED,
    });
  } catch (error) {
    dispatch({
      type: EDIT_STUDYMATERIAL_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteStudymaterial = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/studymaterials/${id}`);
    dispatch(setAlert("Studymaterial Deleted Successfully", "success"));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllStudymaterials = ({ term, value }) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_STUDYMATERIALS_STATED,
    });
    const { data } = await api.get(`/studymaterials/all?term=${term}&value=${value}`);

    dispatch({
      type: GET_ALL_STUDYMATERIALS,
      payload: data,
    });
    dispatch({
      type: GET_ALL_STUDYMATERIALS_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_STUDYMATERIALS_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};

export const handleErrorLocal = () => async (dispatch) => {};
