import {
  GET_VIDEOCOURSES_STATED,
  GET_VIDEOCOURSES,
  GET_VIDEOCOURSES_ENDED,
  ADD_VIDEOCOURSE_STATED,
  ADD_VIDEOCOURSE,
  ADD_VIDEOCOURSE_ENDED,
  EDIT_VIDEOCOURSE_STATED,
  EDIT_VIDEOCOURSE,
  EDIT_VIDEOCOURSE_ENDED,
  GET_VIDEOCOURSE_STATED,
  GET_VIDEOCOURSE,
  GET_VIDEOCOURSE_ENDED,
  GET_ALL_VIDEOCOURSES_STATED,
  GET_ALL_VIDEOCOURSES,
  GET_ALL_VIDEOCOURSES_ENDED
} from "../types/videocourse_type";

const initialState = {
  videocourses_loading: true,
  videocourses: null,
  page: null,
  pages: null,
  total_videocourses: 0,

  videocourse: null,
  videocourse_loading: null,

  loading: true,

  videocourse_message: null,
  all_videocourses: null,
  all_videocourses_loading: null,
  add_videocourse_loading: true,
  edit_videocourse_loading: true
};

export const videocourse_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_VIDEOCOURSES_STATED:
      return {
        ...state,
        videocourses: null,
        pages: null,
        page: null,
        total_videocourses: 0,
        videocourses_loading: true
      };
    case GET_VIDEOCOURSES:
      return {
        ...state,
        videocourses: payload.videocourses,
        pages: payload.pages,
        page: payload.page,
        total_videocourses: payload.count
      };
    case GET_VIDEOCOURSES_ENDED:
      return {
        ...state,
        videocourses_loading: false
      };
    case GET_ALL_VIDEOCOURSES_STATED:
      return {
        ...state,
        all_videocourses_loading: true,
        all_videocourses: null
      };
    case GET_ALL_VIDEOCOURSES:
      return {
        ...state,
        all_videocourses: payload
      };
    case GET_ALL_VIDEOCOURSES_ENDED:
      return {
        ...state,
        all_videocourses_loading: false
      };

    case ADD_VIDEOCOURSE_STATED:
      return {
        ...state,
        videocourse_message: null,
        add_videocourse_loading: true
      };
    case ADD_VIDEOCOURSE:
      return {
        ...state,
        videocourse_message: payload
      };
    case ADD_VIDEOCOURSE_ENDED:
      return {
        ...state,
        add_videocourse_loading: false
      };
    case GET_VIDEOCOURSE_STATED:
      return {
        ...state,
        videocourse: null,
        videocourse_loading: true
      };
    case GET_VIDEOCOURSE:
      return {
        ...state,
        videocourse: payload
      };
    case GET_VIDEOCOURSE_ENDED:
      return {
        ...state,
        videocourse_loading: false
      };
    case EDIT_VIDEOCOURSE_STATED:
      return {
        ...state,
        videocourse_message: null,
        edit_videocourse_loading: true
      };
    case EDIT_VIDEOCOURSE:
      return {
        ...state,
        videocourse_message: payload
      };
    case EDIT_VIDEOCOURSE_ENDED:
      return {
        ...state,
        edit_videocourse_loading: false
      };

    default:
      return state;
  }
};
