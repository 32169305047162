export const GET_VIDEOCOURSES_STATED = "GET_VIDEOCOURSES_STATED";
export const GET_VIDEOCOURSES = "GET_VIDEOCOURSES";
export const GET_VIDEOCOURSES_ENDED = "GET_VIDEOCOURSES_ENDED";
export const ADD_VIDEOCOURSE_STATED = "ADD_VIDEOCOURSE_STARTED";
export const ADD_VIDEOCOURSE = "ADD_VIDEOCOURSE";
export const ADD_VIDEOCOURSE_ENDED = "ADD_VIDEOCOURSE_ENDED";
export const EDIT_VIDEOCOURSE_STATED = "EDIT_VIDEOCOURSE_STATED";
export const EDIT_VIDEOCOURSE = "EDIT_VIDEOCOURSE";
export const EDIT_VIDEOCOURSE_ENDED = "EDIT_VIDEOCOURSE_ENDED";
export const GET_VIDEOCOURSE = "GET_VIDEOCOURSE";
export const GET_VIDEOCOURSE_STATED = "GET_VIDEOCOURSE_STATED";
export const GET_VIDEOCOURSE_ENDED = "GET_VIDEOCOURSE_ENDED";
export const RESET_VIDEOCOURSE = "RESET_VIDEOCOURSE";
export const ERROR_VIDEOCOURSE = "ERROR_VIDEOCOURSE";
export const GET_ALL_VIDEOCOURSES_STATED = "GET_ALL_VIDEOCOURSES_STATED";
export const GET_ALL_VIDEOCOURSES = "GET_ALL_VIDEOCOURSES";
export const GET_ALL_VIDEOCOURSES_ENDED = "GET_ALL_VIDEOCOURSES_ENDED";
