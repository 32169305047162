export const GET_STUDYMATERIALS_STATED = "GET_STUDYMATERIALS_STATED";
export const GET_STUDYMATERIALS = "GET_STUDYMATERIALS";
export const GET_STUDYMATERIALS_ENDED = "GET_STUDYMATERIALS_ENDED";
export const ADD_STUDYMATERIAL_STATED = "ADD_STUDYMATERIAL_STARTED";
export const ADD_STUDYMATERIAL = "ADD_STUDYMATERIAL";
export const ADD_STUDYMATERIAL_ENDED = "ADD_STUDYMATERIAL_ENDED";
export const EDIT_STUDYMATERIAL_STATED = "EDIT_STUDYMATERIAL_STATED";
export const EDIT_STUDYMATERIAL = "EDIT_STUDYMATERIAL";
export const EDIT_STUDYMATERIAL_ENDED = "EDIT_STUDYMATERIAL_ENDED";
export const GET_STUDYMATERIAL = "GET_STUDYMATERIAL";
export const GET_STUDYMATERIAL_STATED = "GET_STUDYMATERIAL_STATED";
export const GET_STUDYMATERIAL_ENDED = "GET_STUDYMATERIAL_ENDED";
export const RESET_STUDYMATERIAL = "RESET_STUDYMATERIAL";
export const ERROR_STUDYMATERIAL = "ERROR_STUDYMATERIAL";
export const GET_ALL_STUDYMATERIALS_STATED = "GET_ALL_STUDYMATERIALS_STATED";
export const GET_ALL_STUDYMATERIALS = "GET_ALL_STUDYMATERIALS";
export const GET_ALL_STUDYMATERIALS_ENDED = "GET_ALL_STUDYMATERIALS_ENDED";
