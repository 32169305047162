export const GET_VIDEOCATEGORYS_STATED = "GET_VIDEOCATEGORYS_STATED";
export const GET_VIDEOCATEGORYS = "GET_VIDEOCATEGORYS";
export const GET_VIDEOCATEGORYS_ENDED = "GET_VIDEOCATEGORYS_ENDED";
export const ADD_VIDEOCATEGORY_STATED = "ADD_VIDEOCATEGORY_STARTED";
export const ADD_VIDEOCATEGORY = "ADD_VIDEOCATEGORY";
export const ADD_VIDEOCATEGORY_ENDED = "ADD_VIDEOCATEGORY_ENDED";
export const EDIT_VIDEOCATEGORY_STATED = "EDIT_VIDEOCATEGORY_STATED";
export const EDIT_VIDEOCATEGORY = "EDIT_VIDEOCATEGORY";
export const EDIT_VIDEOCATEGORY_ENDED = "EDIT_VIDEOCATEGORY_ENDED";
export const GET_VIDEOCATEGORY = "GET_VIDEOCATEGORY";
export const GET_VIDEOCATEGORY_STATED = "GET_VIDEOCATEGORY_STATED";
export const GET_VIDEOCATEGORY_ENDED = "GET_VIDEOCATEGORY_ENDED";
export const RESET_VIDEOCATEGORY = "RESET_VIDEOCATEGORY";
export const ERROR_VIDEOCATEGORY = "ERROR_VIDEOCATEGORY";
export const GET_ALL_VIDEOCATEGORYS_STATED = "GET_ALL_VIDEOCATEGORYS_STATED";
export const GET_ALL_VIDEOCATEGORYS = "GET_ALL_VIDEOCATEGORYS";
export const GET_ALL_VIDEOCATEGORYS_ENDED = "GET_ALL_VIDEOCATEGORYS_ENDED";
