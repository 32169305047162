import {
  GET_PORTFOLIOS_STATED,
  GET_PORTFOLIOS,
  GET_PORTFOLIOS_ENDED,
  ADD_PORTFOLIO_STATED,
  ADD_PORTFOLIO,
  ADD_PORTFOLIO_ENDED,
  EDIT_PORTFOLIO_STATED,
  EDIT_PORTFOLIO,
  EDIT_PORTFOLIO_ENDED,
  GET_PORTFOLIO_STATED,
  GET_PORTFOLIO,
  GET_PORTFOLIO_ENDED,
  GET_ALL_PORTFOLIOS_STATED,
  GET_ALL_PORTFOLIOS,
  GET_ALL_PORTFOLIOS_ENDED
} from "../types/portfolio_type";

const initialState = {
  portfolios_loading: true,
  portfolios: null,
  page: null,
  pages: null,
  total_portfolios: 0,

  portfolio: null,
  portfolio_loading: null,

  loading: true,

  portfolio_message: null,
  all_portfolios: null,
  all_portfolios_loading: null,
  add_portfolio_loading: true,
  edit_portfolio_loading: true
};

export const portfolio_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_PORTFOLIOS_STATED:
      return {
        ...state,
        portfolios: null,
        pages: null,
        page: null,
        total_portfolios: 0,
        portfolios_loading: true
      };
    case GET_PORTFOLIOS:
      return {
        ...state,
        portfolios: payload.portfolios,
        pages: payload.pages,
        page: payload.page,
        total_portfolios: payload.count
      };
    case GET_PORTFOLIOS_ENDED:
      return {
        ...state,
        portfolios_loading: false
      };
    case GET_ALL_PORTFOLIOS_STATED:
      return {
        ...state,
        all_portfolios_loading: true,
        all_portfolios: null
      };
    case GET_ALL_PORTFOLIOS:
      return {
        ...state,
        all_portfolios: payload
      };
    case GET_ALL_PORTFOLIOS_ENDED:
      return {
        ...state,
        all_portfolios_loading: false
      };

    case ADD_PORTFOLIO_STATED:
      return {
        ...state,
        portfolio_message: null,
        add_portfolio_loading: true
      };
    case ADD_PORTFOLIO:
      return {
        ...state,
        portfolio_message: payload
      };
    case ADD_PORTFOLIO_ENDED:
      return {
        ...state,
        add_portfolio_loading: false
      };
    case GET_PORTFOLIO_STATED:
      return {
        ...state,
        portfolio: null,
        portfolio_loading: true
      };
    case GET_PORTFOLIO:
      return {
        ...state,
        portfolio: payload
      };
    case GET_PORTFOLIO_ENDED:
      return {
        ...state,
        portfolio_loading: false
      };
    case EDIT_PORTFOLIO_STATED:
      return {
        ...state,
        portfolio_message: null,
        edit_portfolio_loading: true
      };
    case EDIT_PORTFOLIO:
      return {
        ...state,
        portfolio_message: payload
      };
    case EDIT_PORTFOLIO_ENDED:
      return {
        ...state,
        edit_portfolio_loading: false
      };

    default:
      return state;
  }
};
