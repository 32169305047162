export const GET_CELEBRITYS_STATED = "GET_CELEBRITYS_STATED";
export const GET_CELEBRITYS = "GET_CELEBRITYS";
export const GET_CELEBRITYS_ENDED = "GET_CELEBRITYS_ENDED";
export const ADD_CELEBRITY_STATED = "ADD_CELEBRITY_STARTED";
export const ADD_CELEBRITY = "ADD_CELEBRITY";
export const ADD_CELEBRITY_ENDED = "ADD_CELEBRITY_ENDED";
export const EDIT_CELEBRITY_STATED = "EDIT_CELEBRITY_STATED";
export const EDIT_CELEBRITY = "EDIT_CELEBRITY";
export const EDIT_CELEBRITY_ENDED = "EDIT_CELEBRITY_ENDED";
export const GET_CELEBRITY = "GET_CELEBRITY";
export const GET_CELEBRITY_STATED = "GET_CELEBRITY_STATED";
export const GET_CELEBRITY_ENDED = "GET_CELEBRITY_ENDED";
export const RESET_CELEBRITY = "RESET_CELEBRITY";
export const ERROR_CELEBRITY = "ERROR_CELEBRITY";
export const GET_ALL_CELEBRITYS_STATED = "GET_ALL_CELEBRITYS_STATED";
export const GET_ALL_CELEBRITYS = "GET_ALL_CELEBRITYS";
export const GET_ALL_CELEBRITYS_ENDED = "GET_ALL_CELEBRITYS_ENDED";
