import {
  GET_CELEBRITYS_STATED,
  GET_CELEBRITYS,
  GET_CELEBRITYS_ENDED,
  ADD_CELEBRITY_STATED,
  ADD_CELEBRITY,
  ADD_CELEBRITY_ENDED,
  EDIT_CELEBRITY_STATED,
  EDIT_CELEBRITY,
  EDIT_CELEBRITY_ENDED,
  GET_CELEBRITY_STATED,
  GET_CELEBRITY,
  GET_CELEBRITY_ENDED,
  GET_ALL_CELEBRITYS_STATED,
  GET_ALL_CELEBRITYS,
  GET_ALL_CELEBRITYS_ENDED
} from "../types/celebrity_type";

const initialState = {
  celebritys_loading: true,
  celebritys: null,
  page: null,
  pages: null,
  total_celebritys: 0,

  celebrity: null,
  celebrity_loading: null,

  loading: true,

  celebrity_message: null,
  all_celebritys: null,
  all_celebritys_loading: null,
  add_celebrity_loading: true,
  edit_celebrity_loading: true
};

export const celebrity_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_CELEBRITYS_STATED:
      return {
        ...state,
        celebritys: null,
        pages: null,
        page: null,
        total_celebritys: 0,
        celebritys_loading: true
      };
    case GET_CELEBRITYS:
      return {
        ...state,
        celebritys: payload.celebritys,
        pages: payload.pages,
        page: payload.page,
        total_celebritys: payload.count
      };
    case GET_CELEBRITYS_ENDED:
      return {
        ...state,
        celebritys_loading: false
      };
    case GET_ALL_CELEBRITYS_STATED:
      return {
        ...state,
        all_celebritys_loading: true,
        all_celebritys: null
      };
    case GET_ALL_CELEBRITYS:
      return {
        ...state,
        all_celebritys: payload
      };
    case GET_ALL_CELEBRITYS_ENDED:
      return {
        ...state,
        all_celebritys_loading: false
      };

    case ADD_CELEBRITY_STATED:
      return {
        ...state,
        celebrity_message: null,
        add_celebrity_loading: true
      };
    case ADD_CELEBRITY:
      return {
        ...state,
        celebrity_message: payload
      };
    case ADD_CELEBRITY_ENDED:
      return {
        ...state,
        add_celebrity_loading: false
      };
    case GET_CELEBRITY_STATED:
      return {
        ...state,
        celebrity: null,
        celebrity_loading: true
      };
    case GET_CELEBRITY:
      return {
        ...state,
        celebrity: payload
      };
    case GET_CELEBRITY_ENDED:
      return {
        ...state,
        celebrity_loading: false
      };
    case EDIT_CELEBRITY_STATED:
      return {
        ...state,
        celebrity_message: null,
        edit_celebrity_loading: true
      };
    case EDIT_CELEBRITY:
      return {
        ...state,
        celebrity_message: payload
      };
    case EDIT_CELEBRITY_ENDED:
      return {
        ...state,
        edit_celebrity_loading: false
      };

    default:
      return state;
  }
};
