export const GET_JOBS_STATED = "GET_JOBS_STATED";
export const GET_JOBS = "GET_JOBS";
export const GET_JOBS_ENDED = "GET_JOBS_ENDED";
export const ADD_JOB_STATED = "ADD_JOB_STARTED";
export const ADD_JOB = "ADD_JOB";
export const ADD_JOB_ENDED = "ADD_JOB_ENDED";
export const EDIT_JOB_STATED = "EDIT_JOB_STATED";
export const EDIT_JOB = "EDIT_JOB";
export const EDIT_JOB_ENDED = "EDIT_JOB_ENDED";
export const GET_JOB = "GET_JOB";
export const GET_JOB_STATED = "GET_JOB_STATED";
export const GET_JOB_ENDED = "GET_JOB_ENDED";
export const RESET_JOB = "RESET_JOB";
export const ERROR_JOB = "ERROR_JOB";
export const GET_ALL_JOBS_STATED = "GET_ALL_JOBS_STATED";
export const GET_ALL_JOBS = "GET_ALL_JOBS";
export const GET_ALL_JOBS_ENDED = "GET_ALL_JOBS_ENDED";
