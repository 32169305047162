export const PAGE_TITLE = "Customers";
export const PAGE_SINGLE_TITLE = "Customer";
export const LINK_URL = "customers";
export const inputFields = {
  name: {
    type: "string",
    required: true,
    title: "Name",
    inputType: "",
  },
  phone: {
    type: "string",
    required: true,
    title: "Phone",
    inputType: "",
  },
  email: {
    type: "string",
    required: false,
    title: "Email",
    inputType: "",
  },
  password: {
    type: "string",
    required: true,
    title: "Password",
    inputType: "password",
  },
};
export const initialValues = {
  name: "",
  phone: "",
  email: "",
  password: "",
};

export const view_all_table = [
  { name: "Name", value: "name" },
  { name: "Phone", value: "phone" },
  { name: "Email", value: "email" },
];

export const SIDEBAR_OPTIONS = [
  {
    id: "name",
    field: "name",
    label: "Name",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
];
