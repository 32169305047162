import api from "../../domain/api";
import {
  GET_JOBS_STATED,
  GET_JOBS,
  GET_JOBS_ENDED,
  ADD_JOB_STATED,
  ADD_JOB,
  ADD_JOB_ENDED,
  EDIT_JOB_STATED,
  EDIT_JOB,
  EDIT_JOB_ENDED,
  GET_JOB_STATED,
  GET_JOB,
  GET_JOB_ENDED,
  GET_ALL_JOBS_STATED,
  GET_ALL_JOBS,
  GET_ALL_JOBS_ENDED,
} from "../types/job_type";
import * as qs from "qs";
import { handleError } from "../../shared/handleError";
import { setAlert } from "./alert";

export const addJob = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_JOB_STATED,
    });
    const { data } = await api.post(`/jobs`, formData);
    dispatch({
      type: ADD_JOB,
      payload: data,
    });
    dispatch({
      type: ADD_JOB_ENDED,
    });
  } catch (error) {
    dispatch({
      type: ADD_JOB_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getJobs =
  ({ pageNumber = ""}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_JOBS_STATED,
      });
      const queryParams = qs.parse(window.location.search.replace("?", ""));
      const query = qs.stringify(queryParams, {
        encodeValuesOnly: true, // prettify url
      });

      const { data } = await api.get(
        `/jobs?&pageNumber=${pageNumber}&${query}`
      );

      dispatch({
        type: GET_JOBS,
        payload: data,
      });
      dispatch({
        type: GET_JOBS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_JOBS_ENDED,
      });
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const getJob = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_JOB_STATED,
    });
    const { data } = await api.get(`/jobs/${id}`);

    dispatch({
      type: GET_JOB,
      payload: data,
    });
    dispatch({
      type: GET_JOB_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_JOB_STATED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editJob = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_JOB_STATED,
    });
    const { data } = await api.put(`/jobs/${id}`, formData);
    dispatch({
      type: EDIT_JOB,
      payload: data,
    });
    dispatch({
      type: EDIT_JOB_ENDED,
    });
  } catch (error) {
    dispatch({
      type: EDIT_JOB_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteJob = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/jobs/${id}`);
    dispatch(setAlert("Job Deleted Successfully", "success"));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllJobs = ({ term, value }) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_JOBS_STATED,
    });
    const { data } = await api.get(`/jobs/all?term=${term}&value=${value}`);

    dispatch({
      type: GET_ALL_JOBS,
      payload: data,
    });
    dispatch({
      type: GET_ALL_JOBS_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_JOBS_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};

export const handleErrorLocal = () => async (dispatch) => {};
