import {
  GET_APPLICANTS_STATED,
  GET_APPLICANTS,
  GET_APPLICANTS_ENDED,
  ADD_APPLICANT_STATED,
  ADD_APPLICANT,
  ADD_APPLICANT_ENDED,
  EDIT_APPLICANT_STATED,
  EDIT_APPLICANT,
  EDIT_APPLICANT_ENDED,
  GET_APPLICANT_STATED,
  GET_APPLICANT,
  GET_APPLICANT_ENDED,
  GET_ALL_APPLICANTS_STATED,
  GET_ALL_APPLICANTS,
  GET_ALL_APPLICANTS_ENDED
} from "../types/applicant_type";

const initialState = {
  applicants_loading: true,
  applicants: null,
  page: null,
  pages: null,
  total_applicants: 0,

  applicant: null,
  applicant_loading: null,

  loading: true,

  applicant_message: null,
  all_applicants: null,
  all_applicants_loading: null,
  add_applicant_loading: true,
  edit_applicant_loading: true
};

export const applicant_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_APPLICANTS_STATED:
      return {
        ...state,
        applicants: null,
        pages: null,
        page: null,
        total_applicants: 0,
        applicants_loading: true
      };
    case GET_APPLICANTS:
      return {
        ...state,
        applicants: payload.applicants,
        pages: payload.pages,
        page: payload.page,
        total_applicants: payload.count
      };
    case GET_APPLICANTS_ENDED:
      return {
        ...state,
        applicants_loading: false
      };
    case GET_ALL_APPLICANTS_STATED:
      return {
        ...state,
        all_applicants_loading: true,
        all_applicants: null
      };
    case GET_ALL_APPLICANTS:
      return {
        ...state,
        all_applicants: payload
      };
    case GET_ALL_APPLICANTS_ENDED:
      return {
        ...state,
        all_applicants_loading: false
      };

    case ADD_APPLICANT_STATED:
      return {
        ...state,
        applicant_message: null,
        add_applicant_loading: true
      };
    case ADD_APPLICANT:
      return {
        ...state,
        applicant_message: payload
      };
    case ADD_APPLICANT_ENDED:
      return {
        ...state,
        add_applicant_loading: false
      };
    case GET_APPLICANT_STATED:
      return {
        ...state,
        applicant: null,
        applicant_loading: true
      };
    case GET_APPLICANT:
      return {
        ...state,
        applicant: payload
      };
    case GET_APPLICANT_ENDED:
      return {
        ...state,
        applicant_loading: false
      };
    case EDIT_APPLICANT_STATED:
      return {
        ...state,
        applicant_message: null,
        edit_applicant_loading: true
      };
    case EDIT_APPLICANT:
      return {
        ...state,
        applicant_message: payload
      };
    case EDIT_APPLICANT_ENDED:
      return {
        ...state,
        edit_applicant_loading: false
      };

    default:
      return state;
  }
};
