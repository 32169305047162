export const PAGE_TITLE = 'Study Materials'
export const PAGE_SINGLE_TITLE = 'Study Material'
export const LINK_URL = 'study-materials'
export const inputFields = {
  course: {
    type: 'related',
    required: true,
    title: 'Course',
    inputType: '',
    options: '',
    field: 'title',
  },
  image: {
    type: 'file',
    required: false,
    title: 'Image (360*180)',
    inputType: '',
    options: '',
    field: '',
  },
  title: {
    type: 'string',
    required: true,
    title: 'Title',
    inputType: '',
    options: '',
    field: '',
  },
  is_free: {
    type: 'checkbox',
    required: false,
    title: 'Is free?',
    inputType: '',
    options: '',
    field: '',
  },
  duration: {
    type: 'string',
    required: false,
    title: 'Duration',
    inputType: '',
    options: '',
    field: '',
  },
  chapter: {
    type: 'string',
    required: true,
    title: 'Chapter',
    inputType: 'number',
    options: '',
    field: '',
  },
  video_link: {
    type: 'string',
    required: true,
    title: 'Video Link',
    inputType: '',
    options: '',
    field: '',
  },
}
export const initialValues = {
  name: '',
}

export const view_all_table = [
  { name: 'Chapter', value: 'chapter' },
  { name: 'Title', value: 'title' },
  { name: 'is Free', value: 'is_free', boolean: true },
  { name: 'Duration', value: 'duration' },
  { name: 'Course', value: 'course', related: true, field: 'title' },
]

export const SIDEBAR_OPTIONS = [
  {
    id: 'name',
    field: 'name',
    label: 'Name',
    type: 'string',
    search_type: 'search',
    inputType: 'text',
    condition: '',
  },
  {
    id: 'course',
    field: 'course',
    label: 'Course',
    type: 'related',
    search_type: 'exact',
    inputType: 'text',
    condition: '',
  },
]
