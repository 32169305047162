import { combineReducers } from "redux";
import alert from "./alert_reducer";
import auth from "./auth_reducer";
import { category_reducer } from "./category_reducer";
import { agencybanner_reducer } from "./agencybanner_reducer";
import { applicant_reducer } from "./applicant_reducer";
import { headerbanner_reducer } from "./headerbanner_reducer";
import { middlebanner_reducer } from "./middlebanner_reducer";
import { castingbanner_reducer } from "./castingbanner_reducer";
import { newsletter_reducer } from "./newsletter_reducer";
import { contact_reducer } from "./contact_reducer";
import { coupon_reducer } from "./coupon_reducer";
import { job_reducer } from "./job_reducer";
import { videocategory_reducer } from "./videocategory_reducer";
import { videocourse_reducer } from "./videocourse_reducer";
import { studymaterial_reducer } from "./studymaterial_reducer";
import { order_reducer } from "./order_reducer";
import { customer_reducer } from "./customer_reducer";
import { portfolio_reducer } from "./portfolio_reducer";
import { modal_reducer } from "./modal_reducer";
import { celebrity_reducer } from "./celebrity_reducer";

export default combineReducers({
  alert,
  auth,
  category: category_reducer,
  agencybanner: agencybanner_reducer,
  applicant: applicant_reducer,
  headerbanner: headerbanner_reducer,
  middlebanner: middlebanner_reducer,
  castingbanner: castingbanner_reducer,
  newsletter: newsletter_reducer,
  contact: contact_reducer,
  coupon: coupon_reducer,
  job: job_reducer,
  videocategory: videocategory_reducer,
  videocourse: videocourse_reducer,
  studymaterial: studymaterial_reducer,
  order: order_reducer,
  customer: customer_reducer,
  portfolio: portfolio_reducer,
  modal: modal_reducer,
  celebrity: celebrity_reducer,
});
