import {
  GET_STUDYMATERIALS_STATED,
  GET_STUDYMATERIALS,
  GET_STUDYMATERIALS_ENDED,
  ADD_STUDYMATERIAL_STATED,
  ADD_STUDYMATERIAL,
  ADD_STUDYMATERIAL_ENDED,
  EDIT_STUDYMATERIAL_STATED,
  EDIT_STUDYMATERIAL,
  EDIT_STUDYMATERIAL_ENDED,
  GET_STUDYMATERIAL_STATED,
  GET_STUDYMATERIAL,
  GET_STUDYMATERIAL_ENDED,
  GET_ALL_STUDYMATERIALS_STATED,
  GET_ALL_STUDYMATERIALS,
  GET_ALL_STUDYMATERIALS_ENDED
} from "../types/studymaterial_type";

const initialState = {
  studymaterials_loading: true,
  studymaterials: null,
  page: null,
  pages: null,
  total_studymaterials: 0,

  studymaterial: null,
  studymaterial_loading: null,

  loading: true,

  studymaterial_message: null,
  all_studymaterials: null,
  all_studymaterials_loading: null,
  add_studymaterial_loading: true,
  edit_studymaterial_loading: true
};

export const studymaterial_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_STUDYMATERIALS_STATED:
      return {
        ...state,
        studymaterials: null,
        pages: null,
        page: null,
        total_studymaterials: 0,
        studymaterials_loading: true
      };
    case GET_STUDYMATERIALS:
      return {
        ...state,
        studymaterials: payload.studymaterials,
        pages: payload.pages,
        page: payload.page,
        total_studymaterials: payload.count
      };
    case GET_STUDYMATERIALS_ENDED:
      return {
        ...state,
        studymaterials_loading: false
      };
    case GET_ALL_STUDYMATERIALS_STATED:
      return {
        ...state,
        all_studymaterials_loading: true,
        all_studymaterials: null
      };
    case GET_ALL_STUDYMATERIALS:
      return {
        ...state,
        all_studymaterials: payload
      };
    case GET_ALL_STUDYMATERIALS_ENDED:
      return {
        ...state,
        all_studymaterials_loading: false
      };

    case ADD_STUDYMATERIAL_STATED:
      return {
        ...state,
        studymaterial_message: null,
        add_studymaterial_loading: true
      };
    case ADD_STUDYMATERIAL:
      return {
        ...state,
        studymaterial_message: payload
      };
    case ADD_STUDYMATERIAL_ENDED:
      return {
        ...state,
        add_studymaterial_loading: false
      };
    case GET_STUDYMATERIAL_STATED:
      return {
        ...state,
        studymaterial: null,
        studymaterial_loading: true
      };
    case GET_STUDYMATERIAL:
      return {
        ...state,
        studymaterial: payload
      };
    case GET_STUDYMATERIAL_ENDED:
      return {
        ...state,
        studymaterial_loading: false
      };
    case EDIT_STUDYMATERIAL_STATED:
      return {
        ...state,
        studymaterial_message: null,
        edit_studymaterial_loading: true
      };
    case EDIT_STUDYMATERIAL:
      return {
        ...state,
        studymaterial_message: payload
      };
    case EDIT_STUDYMATERIAL_ENDED:
      return {
        ...state,
        edit_studymaterial_loading: false
      };

    default:
      return state;
  }
};
