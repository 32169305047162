export const PAGE_TITLE = "Orders";
export const PAGE_SINGLE_TITLE = "Order";
export const LINK_URL = "orders";
export const inputFields = {
  order_date: {
    type: "string",
    required: true,
    title: "Order Date",
    inputType: "date",
    options: "",
    field: "",
  },
  customer: {
    type: "related",
    required: true,
    title: "Customer",
    inputType: "",
    options: "",
    field: "name",
  },
  courses: {
    type: "related",
    required: true,
    title: "Course",
    inputType: "",
    options: "",
    field: "title",
  },
  sub_total: {
    type: "string",
    required: true,
    title: "Sub Total",
    inputType: "number",
    options: "",
    field: "",
  },
  total_tax: {
    type: "string",
    required: true,
    title: "Total Tax",
    inputType: "number",
    options: "",
    field: "",
  },
  discount: {
    type: "string",
    required: false,
    title: "Discount",
    inputType: "number",
    options: "",
    field: "",
  },
  total: {
    type: "string",
    required: true,
    title: "Total",
    inputType: "number",
    options: "",
    field: "",
  },
  is_paid: {
    type: "checkbox",
    required: false,
    title: "Is Paid?",
    inputType: "",
    options: "",
    field: "",
  },
  payment_method: {
    type: "select",
    required: true,
    title: "Payment Method",
    inputType: "",
    options: ["PREPAID", "COD"],
    field: "",
  },
  status: {
    type: "select",
    required: true,
    title: "Status",
    inputType: "",
    options: [
      "PENDING",
      "PROCESSING",
      "ON HOLD",
      "CANCELLED",
      "REFUNDED",
      "FAILED",
    ],
    field: "",
  },
  notes: {
    type: "string",
    required: false,
    title: "Notes",
    inputType: "",
    options: "",
    field: "",
  },
};
export const initialValues = {
  order_date: "",
  customer: "",
  courses: "",
  sub_total: "",
  total_tax: "",
  discount: "",
  total: "",
  is_paid: "",
  payment_method: "",
  status: "",
  notes: "",
};

export const view_all_table = [
  { name: "Order Date", value: "order_date", date: true },
  { name: "Customer", value: "customer", related: true, field: "name" },
  { name: "Courses", value: "courses", related: true, field: "title" },
  { name: "sub Total", value: "sub_total" },
  { name: "Total Tax", value: "total_tax" },
  { name: "Discount", value: "discount" },
  { name: "Total", value: "total" },
  { name: "Is paid?", value: "is_paid" },
  { name: "Payment Method", value: "payment_method" },
  { name: "Status", value: "status" },
];

export const SIDEBAR_OPTIONS = [
  {
    id: "name",
    field: "name",
    label: "Name",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "status",
    field: "status",
    label: "Status",
    type: "select",
    search_type: "exact",
    inputType: "text",
    options: [
      "PENDING",
      "PROCESSING",
      "ON HOLD",
      "CANCELLED",
      "REFUNDED",
      "FAILED",
    ],
    condition: "",
  },
];
