export const PAGE_TITLE = "Jobs";
export const PAGE_SINGLE_TITLE = "Job";
export const LINK_URL = "jobs";
export const inputFields = {
  title: {
    type: "string",
    required: true,
    title: "Title",
    inputType: "",
    options: "",
    field: "",
  },
  amount: {
    type: "string",
    required: true,
    title: "Amount",
    inputType: "number",
    options: "",
    field: "",
  },
  job_type: {
    type: "string",
    required: true,
    title: "Job Type",
    inputType: "",
    options: "",
    field: "",
  },
  position: {
    type: "string",
    required: true,
    title: "Position",
    inputType: "",
    options: "",
    field: "",
  },
  job_description: {
    type: "html",
    required: true,
    title: "Job Description",
    inputType: "",
    options: "",
    field: "",
  },
  job_role: {
    type: "string",
    required: true,
    title: "Job Role",
    inputType: "",
    options: "",
    field: "",
  },
};
export const initialValues = {
  title: "",
  amount: "",
  job_type: "",
  position: "",
  job_description: "",
  job_role: "",
};

export const view_all_table = [
  { name: "Title", value: "title" },
  { name: "Amount", value: "amount" },
  { name: "Job Type", value: "job_type" },
  { name: "Position", value: "position" },
  { name: "Job Role", value: "job_role" },
];

export const SIDEBAR_OPTIONS = [
  {
    id: "name",
    field: "name",
    label: "Name",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
  {
    id: "job_type",
    field: "job_type",
    label: "Job Type",
    type: "string",
    search_type: "search",
    inputType: "text",
    condition: "",
  },
];
