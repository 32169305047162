import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addPortfolio,
  getPortfolios,
  getPortfolio,
  editPortfolio,
  deletePortfolio,
  getAllPortfolios,
} from "../../store/actions/portfolio_action";
import _debounce from "lodash/debounce";
import { useSelectAllModal } from "./UseModal";

// Get All Data
export const useAllPortfolios = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.portfolio);
  const [pageNumber, setPageNumber] = useState(1);
  const [deleteEntry, setDeleteEntry] = useState(null);

  useEffect(() => {
    if (deleteEntry) {
      dispatch(deletePortfolio(deleteEntry));
    }
    allQuery();
  }, [deleteEntry, pageNumber, window.location.search]);
  const allQuery = useCallback(
    _debounce(() => {
      dispatch(
        getPortfolios({
          pageNumber,
        })
      );
    }, 1000),
    []
  );

  useEffect(() => {
    setPageNumber(1);
  }, [window.location.search]);

  const deleteBtnClicked = async (id) => {
    setDeleteEntry(id);
  };

  return [data, setPageNumber, deleteBtnClicked];
};

// Get Single Data
export const useSinglePortfolio = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.portfolio);
  useEffect(() => {
    dispatch(getPortfolio(id));
  }, [id]);
  return [data];
};
// Add Data
export const useCreatePortfolio = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.portfolio);
  const addData = async (data) => {
    await dispatch(addPortfolio(data));
  };
  return [data, addData];
};
export const useUpdatePortfolio = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.portfolio);
  const updateData = async (id, data) => {
    await dispatch(editPortfolio(id, data));
  };
  return [updateData];
};

export const useSelectAllPortfolio = () => {
  const dispatch = useDispatch();
  const [term, setTerm] = useState("");
  const [value, setValue] = useState("");
  const data = useSelector((state) => state.portfolio);
  useEffect(() => {
    dispatch(getAllPortfolios({ term, value }));
  }, [term, value]);
  return [data, setTerm, setValue];
};

export const useGetDropdownOptions = () => {
  const [modal, setModalSearchField, setModalSearchValue] = useSelectAllModal();

  const [dropdownOptions, setDropdownOptions] = useState({});
  useEffect(() => {
    if (modal && modal.all_modals) {
      const newData = modal.all_modals.map((item) => {
        return { label: item.name, value: item._id };
      });
      setDropdownOptions({ ...dropdownOptions, modal: newData });
    }
  }, [modal]);
  const loadOptions = async (inputValue, callback, field) => {
    if (field == "modal") {
      await setModalSearchField("name");
      await setModalSearchValue(inputValue);
      callback(dropdownOptions.modal);
    }
  };

  return [dropdownOptions, loadOptions];
};
