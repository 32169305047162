import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import DoughnutChart from "../../components/charts/DonughtChart";
import BreadCrumb from "../../components/template/BreadCrumb";
import Header from "../../components/template/Header";
import Spinner from "../../components/layout/Spinner";
// import { useAllDashboards } from "../../shared/hooks/UseDashboard";

const Dashboard = ({ auth: { user } }) => {
  // const [data] = useAllDashboards();
  // console.log("DATA", data);
  // const { dashboards } = data;
  return (
    <div className="pace-done">
      <div>
        <Header />
        <BreadCrumb
          title="Dashboard"
          mainLinkTitle="Dashboard"
          mainLinkUrl="/dashboard"
          activeLink="Main"
        />
        {/* <div className="container-fluid">
          <div className="row">
            <div className="col-md-8">
              {user && (user.role == "SUPER ADMIN" || user.role == "MANAGER") && (
                <div className="row">
                  <div className="col-md-3">
                    <div className="dashboard-stat pending">
                      <div className="report-title">Active Clients </div>
                      <div className="report-stat">
                        {" "}
                        {dashboards && dashboards.total_client
                          ? dashboards.total_client
                          : 0}
                      </div>
                    </div>
                  </div>
                  {dashboards &&
                    dashboards.status_stats &&
                    dashboards.status_stats.map((item) => {
                      return (
                        <div className="col-md-3">
                          <div className="dashboard-stat closed">
                            <div className="report-title">
                              {" "}
                              {item.status ? item.status : "Other Clients"}{" "}
                            </div>
                            <div className="report-stat">{item.count}</div>
                          </div>
                        </div>
                      );
                    })}

                  <div className="col-md-3">
                    <div className="dashboard-stat pending">
                      <div className="report-title">
                        Total Amount To Receive{" "}
                      </div>
                      <div className="report-stat">
                        {" "}
                        ₹{dashboards && dashboards.bill_total}{" "}
                      </div>
                    </div>
                  </div>
                  {dashboards &&
                    dashboards.bill_stats &&
                    dashboards.bill_stats.map((item) => {
                      return (
                        <div className="col-md-3">
                          <div className="dashboard-stat pending">
                            <div className="report-title">{item._id} </div>
                            <div className="report-stat">₹{item.total}</div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}

              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">Pending Tasks</div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table table-stripped">
                          <thead>
                            <tr>
                              <th>Sr No</th>
                              <th>Client Name</th>
                              <th>Task Name</th>
                              <th>Process</th>
                              <th>Assigned To</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {dashboards &&
                              dashboards.activities &&
                              dashboards.activities.map((item, index) => {
                                return (
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>{item.client && item.client.name}</td>
                                    <td>{item.task_name}</td>
                                    <td>{item.process && item.process.name}</td>
                                    <td>
                                      {item.assigned_to &&
                                        item.assigned_to.name}
                                    </td>
                                    <td>
                                      {item.activity_status &&
                                        item.activity_status.name}
                                    </td>
                                    <td>
                                      {" "}
                                      <Link to={`/activitys/${item._id}/view`}>
                                        {" "}
                                        View{" "}
                                      </Link>{" "}
                                    </td>
                                  </tr>
                                );
                              })}
                            <tr></tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="dashboard-stat">
                <h3> Activity </h3>
                <hr />

                <DoughnutChart
                  graph_data={dashboards && dashboards.activities_status_array}
                />
              </div>
              <div className="row">
                {dashboards &&
                  dashboards.activities_status_array &&
                  dashboards.activities_status_array.map((item, index) => {
                    return (
                      <div className="col-md-6">
                        <div className="dashboard-stat pending">
                          <div className="report-title">
                            {" "}
                            {item.status ? item.status : "Other Clients"}{" "}
                          </div>
                          <div className="report-stat">{item.count}</div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({ auth: state.auth });

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
